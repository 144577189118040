import actions from './actions'
import file_server from './file_server'
import repository from './repository'
import entry from './entry'
import network_share from './network_share'
import rendition from './rendition'
import synthetic from './synthetic'
import self_service_template from './self_service_template'

export default {
  namespaced: true,
  modules: {
    actions,
    file_server,
    repository,
    entry,
    network_share,
    rendition,
    synthetic,
    self_service_template
  }
}