import Vue from 'vue'
import EntryMutations from './mutations-types'

export default {
  [EntryMutations.SET_ENTRIES](state, list) {
    state.breadcrumb = [...list.breadcrumb ?? state.breadcrumb]
    state.entries = [...list.entries ?? []]
  },

  [EntryMutations.UPDATE_ENTRY](state, {nodeId, params}) {
    const index = state.entries.findIndex(e => e.node_id == nodeId)

    const updatedObj = {...state.entries[index], ...params}

    Vue.set(state.entries, index, updatedObj)
  },

  [EntryMutations.REMOVE_ENTRY](state, nodeId) {
    const index = state.entries.findIndex(e => e.node_id == nodeId)

    state.entries.splice(index, 1)
  },
}
