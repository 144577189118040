<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 24 24">
    <rect class="clas-exc-2" x="2.95" y="3" width="18.11" height="18" rx="1.02" ry="1.02" />
    <path class="clas-exc-1"
      d="M12.03,10.56l1.81-3.33h2.29l-2.81,4.79,2.89,4.87h-2.32l-1.86-3.39-1.86,3.39h-2.32l2.89-4.87-2.81-4.79h2.29l1.81,3.33Z" />
  </svg>
</template>

<style>
  .clas-exc-1 {
    fill: #fff;
  }

  .clas-exc-2 {
    fill: #15854d;
  }
</style>

<script>
  export default {
    props: {
      iconName: {
        type: String,
        default: 'office_excel'
      },
      width: {
        type: [Number, String],
        default: 24
      },
      height: {
        type: [Number, String],
        default: 24
      },
    }
  }
</script>