import FilterMutations from './mutations-types'

export default {
  [FilterMutations.SET_FILTERS](state, filters) {
    state.filters = [...filters]
  },

  [FilterMutations.SET_SELECTED_FILTER](state, filter) {
    state.selectedFilter = filter

    state.fields.forEach((field) => {
      field.value = null

      if (filter) {
        field.value = filter[field.name]
      }
    })
  },

  [FilterMutations.SET_FIELD_TENANTS](state, tenants) {
    state.fields.find(f => f.name == 'tenant_id').items = tenants
  },

  [FilterMutations.SET_FIELDS_LIST](state, fields) {
    state.fieldsList = [...fields]
  },

  [FilterMutations.SET_GROUPS_LIST](state, groups) {
    state.groupsList = [...groups]
  },
}