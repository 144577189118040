import AxiosCdp from '@/services/api/cdp/axios_cdp'

export default {
  getAll: (params) => {
    return AxiosCdp.get('cdp/attribute', {params})
  },

  createAttributeMapping: (data) => {
    return AxiosCdp.post('cdp/attribute_with_property', data)
  },

  updateAttributeMapping: (data, id) => {
    return AxiosCdp.put(`cdp/attribute/${id}`, data)
  },

  deleteAttributeMapping: (id) => {
    return AxiosCdp.delete(`cdp/attribute/${id}`)
  },
}
