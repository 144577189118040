import i18n from '@/i18n.js'

export default {
  fields: [
    {
      name: 'group_title',
      type: 'text',
      label: 'Title',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      autofocus: true,
      readonly: false,
      replicateValToIdx: 1
    },
    {
      name: 'group_key',
      type: 'slug',
      label: 'Key',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      required: true,
      hide: false,
      replicable: true,
      value: null,
    },

    {
      name: 'theme',
      type: 'select',
      label: 'Theme',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      items: [],
      required: false,
      hide: false,
      value: null,
      replicateValToIdx: 1,
      itemText: 'theme_title',
      itemValue: 'theme_id',
    },

    {
      name: 'tenant_id',
      type: 'select',
      label: 'Tenant',
      itemText: 'title',
      itemValue: 'tenant_id',
      items: [],
      value: null,
      changeEvent: 'tenant-change'
    },
    {
      name: 'company_id',
      type: 'select',
      label: 'Company',
      itemText: 'name',
      itemValue: 'company_id',
      items: [],
      value: null
    },
    {
      name: 'application_ids',
      type: 'select',
      label: 'Applications',
      items: [],
      itemText: 'title',
      itemValue: 'id',
      chips: true,
      multiple: true
    },
    {
      name: 'application_instance_ids',
      type: 'select',
      label: 'Application Instances',
      items: [],
      itemText: 'application_instance_title',
      itemValue: 'application_instance_id',
      chips: true,
      multiple: true
    },
    {
      name: 'active',
      type: 'switch',
      label: 'Active',
      itemText: 'name',
      value: true
    },
  ],

  tableHeaders: [
    {text: 'Key', value: 'group_key'},
    {text: 'Tenant', value: 'tenant_id'},
    {text: 'Company', value: 'company_id'},
    {text: 'Applications', value: 'application_ids'},
    {text: 'Actions', value: 'actions', sortable: false}
  ],

  groups: [],

  selectedGroup: null,

  selectedTenant: null,

  lastSearchTenants: [],

  lastSearchCompanies: [],

  inactiveFilterValue: false
}
