import AxiosNode from '@/services/api/node/axios_node'

export default {
  getActions: (data) => {
    return AxiosNode.get('/action', data)
  },

  getNodeData: (nodeId) => {
    return AxiosNode.get('/node/' + nodeId)
  }
}