import i18n from '@/i18n.js'

export default {
  actionsets: [],

  tableHeaders: [
    {text: 'Key', value: 'aspect_key'},
    {text: 'Tenant', value: 'tenant_id'},
    {text: 'Actionset actions', value: 'actions_key', sortable: false},
    {text: 'Actions', value: 'actions', sortable: false}
  ],

  selectedActionset: {},

  fields: [
    {
      name: 'aspect_key',
      type: 'text',
      label: 'Key',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      autofocus: true,
      required: true,
      hide: false,
      value: null
    },
    {
      name: 'tenant_id',
      type: 'select',
      label: 'Tenant',
      items: [],
      itemText: 'title',
      itemValue: 'tenant_id',
      required: true,
      hide: false,
      chips: true,
      multiple: false
    },
    {
      name: 'actions_ids',
      type: 'select',
      label: 'Actions',
      items: [],
      itemText: 'action_key',
      itemValue: 'action_id',
      required: true,
      hide: false,
      chips: true,
      multiple: true
    }
  ]
}
