import AxiosCore from '@/services/api/core/axios_core'

export default {
  getThemesAsAdmin(params) {
    return AxiosCore.get(`theme/admin`, { params })
  },

  getUserThemes() {
    return AxiosCore.get(`theme/user`)
  },

  getTheme(themeId) {
    return AxiosCore.get(`theme/${themeId}`)
  },

  linkThemeToTenant(tenantId, themeId) {
    return AxiosCore.put(`theme/${themeId}/tenant/${tenantId}`)
  },

  unlinkThemeToTenant(tenantId, themeId) {
    return AxiosCore.delete(`theme/${themeId}/tenant/${tenantId}`)
  },

  getTenantAvailableThemes(tenantId) {
    return AxiosCore.get(`theme/admin/tenant/${tenantId}`)
  },

  createTheme(data) {
    return AxiosCore.post(`theme`, data)
  },

  updateTheme(data, themeId) {
    return AxiosCore.put(`theme/${themeId}`, data)
  },

  deleteTheme(themeId) {
    return AxiosCore.delete(`theme/${themeId}`)
  },

  linkThemeToGroup(groupId, themeId) {
    return AxiosCore.put(`theme/${themeId}/group/${groupId}`)
  },
}
