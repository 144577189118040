import AxiosContent from '@/services/api/content/axios_content'

export default {
  download: (nodeId) => {
    return AxiosContent.get(`actions/link/${nodeId}`)
  },

  getDownloadEndpoint: (nodeId) => {
    return `actions/download/${nodeId}`
  },
}
