import AxiosContent from '@/services/api/content/axios_content'

export default {
  create: (nodeId, name) => {
    return AxiosContent.post(`/synthetic/${nodeId}`, {name: name})
  },

  get: (nodeId) => {
    return AxiosContent.get(`/synthetic/${nodeId}`)
  },

  save: (nodeId, params) => {
    return AxiosContent.put(`/synthetic/${nodeId}`, params)
  },

  txtToImg: (nodeId, params) => {
    return AxiosContent.post(`/synthetic/txt2img/${nodeId}`, params)
  },

  get3dLibraryList: (nodeId) => {
    return AxiosContent.get(`/synthetic/${nodeId}/3dlib`)
  },

  historical: (nodeId, params) => {
    return AxiosContent.get(`/synthetic/historical/${nodeId}`, {params})
  },

  upscale: (nodeId, upscale) => {
    return AxiosContent.put(`/synthetic/upscale/${nodeId}`, {upscale: upscale})
  }
}
