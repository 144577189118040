import UnomiConsoleMutations from './mutations-types'

export default {
  [UnomiConsoleMutations.ADD_OUTPUT_RESULT](state, {command, result}) {
    command = `unomi:${command}`

    state.outputList = [...state.outputList, command, result]
  },

  [UnomiConsoleMutations.CLEAR_OUTPUT_LIST](state) {
    state.outputList = []
  },
}