export default {
  applicationTitleById: (state) => (id) => {

    let item = state
      .applications
      .find(application => application.id == id)

    if (!item) {
      return ''
    }

    return item.title
  },

  getSelectedContentAppAccessUrl: (state) => () => {
    return state.userApplications.find(userApp => { return userApp.application_instance_id == state.selectedContentApp })?.access_url
  },

  getSelectedCdpAppAccessUrl: (state) => () => {
    return state.userApplications.find(userApp => { return userApp.application_instance_id == state.selectedCdpApp })?.access_url
  },

  getApplicationInstanceById: (state) => (applicationInstanceId) => {
    return state.userApplications.find(appInstance => { return appInstance.application_instance_id == applicationInstanceId})
  },

  getApplicationsByApplicationKey: (state) => (applicationKey) => {
    return state.userApplications.filter(appInstance => { return appInstance.application_key == applicationKey})
  },

  getIconForCoreSettingsItem: (state) => (itemTitle) => {
      return state.coreSettingsItems.find(item => { return itemTitle == item.title}).icon
  },

  getIconForOperationSettingsItem: (state) => (itemTitle) => {
      return state.operationSettingsItems.find(item => { return itemTitle == item.title}).icon
  },

  getIconForCdpSettingsItem: (state) => (itemTitle) => {
    return state.cdpSettingsItems.find(item => { return itemTitle == item.title}).icon
  },

  getIconForContentSettingsItem: (state) => (itemTitle) => {
    return state.contentSettingsItems.find(item => { return itemTitle == item.title}).icon
  },
}
