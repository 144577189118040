export default {
  appInstanceTitleById: (state) => (id) => {
    if (state.applications_instances.items) {
      let item = state
        .applications_instances
        .items
        .find(appInstance => appInstance.application_instance_id == id)

        return item?.application_instance_title || ''
      }

    return ''
  }
}