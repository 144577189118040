import AxiosCore from '@/services/api/core/axios_core'

export default {
  getUser: (usersId) => {
    return AxiosCore.get(`users/${usersId}`)
  },

  createUser: (data) => {
    return AxiosCore.post('users_with_admin', data)
  },

  updateUser: (data, id) => {
    return AxiosCore.put('users/' + id, data)
  },

  getAllUsers: (params) => {
    return AxiosCore.get('users', { params })
  },

  getUserGroups: (params) => {
    return AxiosCore.get('usergroup', { params })
  },

  removeUserTenantAdmin: (usersId, tenantId) => {
    return AxiosCore.delete(`users/${usersId}/tenant/${tenantId}/admin`)
  },

  createUserBatch: (params) => {
    return AxiosCore.put('users/batch/create', params)
  },

  setExternalAccount: (params) => {
    return AxiosCore.put('user/external_account', params)
  }
}
