import jwtDecode from 'jwt-decode';

export function isTokenNearExpiry(token) {
  if (!token) {
    return false;
  }

  const decodedToken = jwtDecode(token);
  const currentTime = Date.now() / 1000;
  const timeRemaining = decodedToken.exp - currentTime;

  return timeRemaining <= process.env.VUE_APP_RENEW_TOKEN_TIME;
}
