import AxiosCore from '@/services/api/core/axios_core'

export default {
  createTenant: (data) => {
    return AxiosCore.post('tenant_with_company', data)
  },

  updateTenant: (data, id) => {
    return AxiosCore.put(`tenant/${id}`, data)
  },

  getAllTenants: (params) => {
    return AxiosCore.get('tenant', {params})
  },

  getTenant: (id) => {
    return AxiosCore.get(`tenant/${id}`)
  },

  deleteTenant: (id) => {
    return AxiosCore.delete(`tenant/${id}`)
  },

  listTenantQuotas: (tenantId) => {
    return AxiosCore.get(`quota/tenant/${tenantId}`)
  },

  createQuota: (data) => {
    return AxiosCore.post('quota/tenant', data)
  },

  editQuota: (data, service, tenantId) => {
    return AxiosCore.put(`quota/${service}/tenant/${tenantId}`, data)
  },

  addCredit(data, service, tenantId) {
    return AxiosCore.put(`quota/deposit/${service}/tenant/${tenantId}`, data)
  },

  getServiceTenantQuota(service, tenantId) {
    return AxiosCore.get(`quota/${service}/tenant/${tenantId}`)
  },
}
