import api from '@/services/api'
import SecurityCategoryActions from './actions-types'

export default {
  [SecurityCategoryActions.GET_ALL_SECURITYCATEGORIES]({}, options) {
    return new Promise((resolve, reject) => {
      api.node.security
        .getMarkCategories(options)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => reject())
    })
  },

  [SecurityCategoryActions.SAVE_SECURITYCATEGORY]({ state }) {
    return new Promise((resolve, reject) => {
      let data = {}
      state.fields.forEach((item) => {
        data[item.name] = item.value
      })

      if (state.selectedSecurityCategory) {
        api.node.security
          .updateMarkCategory(data, state.selectedSecurityCategory.mark_category_id)
          .then((response) => {
            resolve(response.body)
          })
          .catch((error) => {
            reject(error.response.data)
          })
      } else {
        api.node.security
          .createMarkCategory(data)
          .then((response) => {
            resolve(response.body)
          })
          .catch((error) => {
            reject(error.response.data)
          })
      }
    })
  },

  [SecurityCategoryActions.UPDATE_SECURITYCATEGORY]({ state }, data) {
    data.aspect_key = state.selectedSecurityCategory.aspect_key

    return new Promise((resolve, reject) => {
      api.node.security
        .updateMarkCategory(data, state.selectedSecurityCategory.mark_category_id)
        .then((response) => {
          resolve(response.body)
        })
        .catch((error) => {
          reject(error.response.data)
        })
    })
  },

  [SecurityCategoryActions.DELETE_SECURITYCATEGORY]({ state }) {
    return new Promise((resolve, reject) => {
      api.node.security
        .deleteMarkCategory(state.selectedSecurityCategory.mark_category_id)
        .then((response) => {
          resolve(response.body)
        })
        .catch((error) => {
          reject(error.response.data)
        })
    })
  }
}
