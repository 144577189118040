import AxiosCdp from '@/services/api/cdp/axios_cdp'

export default {
  getAll: (params) => {
    return AxiosCdp.get('cdp/config', {params})
  },

  createUnomiConfig: (data) => {
    return AxiosCdp.post('cdp/config_with_property', data)
  },

  updateUnomiConfig: (data, id) => {
    return AxiosCdp.put(`cdp/config/${id}`, data)
  },

  deleteUnomiConfig: (id) => {
    return AxiosCdp.delete(`cdp/config/${id}`)
  },
}
