import AxiosContent from '@/services/api/content/axios_content'

export default {
  getNodeActions: (nodeId) => {
    return AxiosContent.get(`actions/node/${nodeId}`)
  },
  generateDownloadLink: (nodeId, params) => {
    return AxiosContent.get(`actions/generate/download-link/${nodeId}`,{ params })
  }
}
