import CompanyMutations from './mutations-types'

export default {
  [CompanyMutations.SET_SELECTED_COMPANY](state, company) {
    state.selectedCompany = company

    state.fields.forEach((field) => {
      field.value = null

      if (company) {
        field.value = company[field.name]
      }
      
      if (field.name == 'name') {
        field.autofocus = true

        if (company) {
          field.autofocus = false
        }
      }

      if (field.name == 'key') {
        field.disabled = false
        field.replicable = true
        
        if (company) {
          field.disabled = true
          field.replicable = false
        }
      }

      if (field.name == 'tenant_id') {
        field.readonly = false

        if (company) {
          field.readonly = true

          const tenantInList = field.items.find(f => f.tenant_id == company.tenant_id)

          if (! tenantInList) {
            field.value = null
          }
        }
      }

      if (field.name == 'active') {
        if (company) {
          field.hide  = false

          return
        }
        
        field.value = true
        field.hide = true
      }
    })
  },

  [CompanyMutations.SET_FIELD_TENANTS](state, tenants) {
    state.fields.find(f => f.name == 'tenant_id').items = tenants
  },

  [CompanyMutations.SET_COMPANIES](state, companies) {
    state.companies = companies
  },

  [CompanyMutations.SET_INACTIVE_FILTER_VAL](state, active) {
    state.inactiveFilterValue = active
  },
}