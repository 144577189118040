export default {
  GET_ALL_SECURITYGROUPS: 'getAllSecurityGroups',
  SAVE_SECURITYGROUP: 'saveSecurityGroup',
  UPDATE_SECURITYGROUP: 'updateSecurityGroup',
  DELETE_SECURITYGROUP: 'deleteSecurityGroup',
  GET_SECURITYGROUP: 'getSecurityGroup',
  GET_SECURITY_GROUPS_BY_TENANT: 'getSecurityGroupsByTenant',
  GET_SECURITY_GROUPS_BY_NODE: 'getSecurityGroupsByNode',
  GET_SECURITY_MARKS_BY_SECURITY_GROUP: 'getSecurityMarksBySecurityGroup',
  ADD_SECURITY_GROUP_NODE_RELATIONSHIP: 'addSecurityGroupNodeRelationship',
  REMOVE_SECURITY_GROUP_NODE_RELATIONSHIP: 'removeSecurityGroupNodeRelationship'
}
