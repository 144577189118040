import SecurityMarkMutations from './mutations-types'

export default {
  [SecurityMarkMutations.SET_SELECTED_SECURITY_MARK](state, securityMark) {
    state.selectedSecurityMark = securityMark

    state.fields.forEach((field) => {
      field.value = null

      if (securityMark) {
        field.value = securityMark[field.name]
      }
    })
  },

  [SecurityMarkMutations.SET_FIELD_SECURITY_CATEGORY](state, securityCategories) {
    state.fields.find(f => f.name == 'mark_category_id').items = securityCategories
  },

  [SecurityMarkMutations.SET_SELECTED_PARENT_MARK](state, selectedParentMark) {
    state.selectedParentMark = selectedParentMark
  }
}