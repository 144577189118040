import AxiosNode from '@/services/api/node/axios_node'

export default {
  getClassificationByNode: (nodeId) => {
    return AxiosNode.get(`/classification/node/${nodeId}`)
  },

  getClassificationRecursiveByNode: (nodeId) => {
    return AxiosNode.get(`/classification/recursive/node/${nodeId}`)
  },

  simulateMarksPermission: (params) => {
    return AxiosNode.post(`/mark/simulate_permission`, params)
  },

  saveSecurityClassification: (classification) => {
    return AxiosNode.put('/classification/collection', classification)
  }
}
