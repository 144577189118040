import api from '@/services/api'
import NetworkShareActions from './actions-types'
import NetworkShareMutations from './mutations-types'
import AppMutations from '@/store/app/mutations-types'

export default {
  [NetworkShareActions.GET_USER_NETWORK_SHARES]({ rootGetters, commit }) {
    const contentApps = rootGetters['app/getApplicationsByApplicationKey']('content')

    if (contentApps?.length <= 0) {
      return
    }

    return new Promise((resolve, reject) => {
      let promises = []
      let list = []

      contentApps.forEach(app => {
        commit(`app/${[AppMutations.SET_SELECTED_CONTENT_APP]}`, app.application_instance_id, { root:true })

        promises = [...promises,
          api.content.network_share
            .getUserNetworkShares()
            .then((result) => {
              list = [...list, ...result.data]
            })
        ]
      })

      Promise.all(promises)
        .finally(() => {
          commit(NetworkShareMutations.SET_USER_NETWORK_SHARES, list)

          resolve(list)
        })
        .catch((error) => reject(error))
    })
  },

  [NetworkShareActions.CONFIGURE_SHARE_USER]({ rootGetters, commit }, {usersId, data}) {
    const contentApps = rootGetters['app/getApplicationsByApplicationKey']('content')

    if (contentApps?.length <= 0) {
      return
    }

    return new Promise((resolve, reject) => {
      let promises = []

      contentApps.forEach(app => {
        commit(`app/${[AppMutations.SET_SELECTED_CONTENT_APP]}`, app.application_instance_id, { root:true })

        promises = [...promises,
          api.content.network_share.configureShareUser(usersId, data)
        ]
      })

      Promise.all(promises)
        .finally(() => resolve())
        .catch((error) => reject(error))
    })
  },

  [NetworkShareActions.SAVE_NETWORK_SHARE]({ }, params) {
    return new Promise((resolve, reject) => {
      api.content.network_share
        .saveNetworkShare(params)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => reject())
    })
  },

  [NetworkShareActions.SAVE_NETWORK_SHARE_PERMISSION]({ }, {networkShareId, params}) {
    return new Promise((resolve, reject) => {
      api.content.network_share
        .saveNetworkSharePermission(networkShareId, params)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => reject())
    })
  },

  [NetworkShareActions.FIND_NETWORK_DRIVE]({ }, nodeId) {
    return new Promise((resolve, reject) => {
      api.content.network_share
        .findNetworkDrive(nodeId)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => reject())
    })
  },

  [NetworkShareActions.DELETE_NETWORK_SHARE_PERMISSION]({ }, networkSharePermissionId) {
    return new Promise((resolve, reject) => {
      api.content.network_share
        .deleteNetworkSharePermission(networkSharePermissionId)
        .then(() => resolve())
        .catch(() => reject())
    })
  },

  [NetworkShareActions.DELETE_NETWORK_SHARE]({ }, networkSharePermissionId) {
    return new Promise((resolve, reject) => {
      api.content.network_share
        .deleteNetworkShare(networkSharePermissionId)
        .then(() => resolve())
        .catch((error) => reject(error))
    })
  }
}
