import api from '@/services/api'
import ActionsetActions from './actions-types'

export default {
  [ActionsetActions.GET_ALL_ACTIONSETS]({}, options) {
    return new Promise((resolve, reject) => {
      api.node.actionset
        .getActionsets(options)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => reject())
    })
  },

  [ActionsetActions.SAVE_ACTIONSET]({ state }) {
    return new Promise((resolve, reject) => {
      let data = {}
      state.fields.forEach((item) => {
        data[item.name] = item.value
      })

      if (state.selectedActionset) {
        api.node.actionset
          .updateActionset(data, state.selectedActionset.actionset_id)
          .then((response) => {
            resolve(response.body)
          })
          .catch((error) => {
            reject(error.response.data)
          })
      } else {
        api.node.actionset
          .createActionset(data)
          .then((response) => {
            resolve(response.body)
          })
          .catch((error) => {
            reject(error.response.data)
          })
      }
    })
  },

  [ActionsetActions.UPDATE_ACTIONSET]({ state }, data) {
    data.aspect_key = state.selectedActionset.aspect_key

    return new Promise((resolve, reject) => {
      api.node.actionset
        .updateActionset(data, state.selectedActionset.actionset_id)
        .then((response) => {
          resolve(response.body)
        })
        .catch((error) => {
          reject(error.response.data)
        })
    })
  },

  [ActionsetActions.GET_ACTIONSETS_BY_NODE]({}, nodeId) {
    return new Promise((resolve, reject) => {
      api.node.actionset
        .getActionsetsByNode(nodeId)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => reject())
    })
  },

  [ActionsetActions.ADD_ACTIONSET_NODE_RELATIONSHIP]({}, params) {
    return new Promise((resolve, reject) => {
      api.node.aspect
        .addAspectNodeRelationship(params.actionsetId, params.nodeId, params.data)
        .then(() => {
          resolve()
        })
        .catch(() => reject())
    })
  },

  [ActionsetActions.REMOVE_ACTIONSET_NODE_RELATIONSHIP]({}, params) {
    return new Promise((resolve, reject) => {
      api.node.aspect
        .removeAspectNodeRelationship(params.actionsetId, params.nodeId)
        .then(() => {
          resolve()
        })
        .catch(() => reject())
    })
  }
}
