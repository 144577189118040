import AxiosNode from '@/services/api/node/axios_node'

export default {
  addAspectNodeRelationship: (aspectId, nodeId, data) => {
    return AxiosNode.put(`/aspect/${aspectId}/node/${nodeId}`, data)
  },

  removeAspectNodeRelationship: (aspectId, nodeId) => {
    return AxiosNode.delete(`/aspect/${aspectId}/node/${nodeId}`)
  }  
}