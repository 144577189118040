import SecurityGroupMutations from './mutations-types'

export default {
  [SecurityGroupMutations.SET_SECURITYGROUPS](state, security_groups) {
    state.security_groups = [...security_groups]
  },

  [SecurityGroupMutations.SET_SELECTED_SECURITYGROUP](state, security_group) {
    state.selectedSecurityGroup = security_group

    state.fields.forEach((field) => {
      field.value = null
      field.readonly = false

      if (security_group) {
        field.value = security_group[field.name]

        const dependentFields = state.fields.filter(f => f.dependencyFieldKey == field.name)

        if (dependentFields) {
          dependentFields.forEach(df => {
            df.items = [...df.itemsToFilter.filter(i => i.dependencyVals.includes(field.value))]
          })
        }
      }
    })
  },

  [SecurityGroupMutations.SET_FIELD_TENANTS](state, tenants) {
    state.fields.find(f => f.name == 'tenant_id').items = tenants
  },

  [SecurityGroupMutations.HIDE_EDIT_FIELDS](state) {
    state.fields.forEach((field) => {
      if (field.name == "security_group_key") {
        return
      }

      field.readonly = true
    })
  },
}