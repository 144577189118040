const loadGsi = function () {
  return new Promise(function (resolve, reject) {
    const src = 'https://accounts.google.com/gsi/client'
    let s = document.querySelector('script[src="' + src + '"]')
    let shouldAppend = false

    if (!s) {
      s = document.createElement('script')
      s.src = src
      s.async =  true

      s.onload = function () {
        s.setAttribute('data-loaded', true)
        resolve()
      }

      s.onerror = reject
      shouldAppend  = true
    } else if (s.hasAttribute('data-loaded')) {
      resolve()
    }
    if (shouldAppend) {      
      document.head.appendChild(s)
    }
  })
}

const loadApi = function () {
  return new Promise(function (resolve, reject) {
    const src = 'https://apis.google.com/js/api.js'
    let s = document.querySelector('script[src="' + src + '"]')
    let shouldAppend = false
    if (!s) {
      s = document.createElement('script')
      s.src = src
      s.async =  true
      s.onload = function () {
        s.setAttribute('data-loaded', true)
        resolve()
      }
      s.onerror = reject
      shouldAppend  = true
    } else if (s.hasAttribute('data-loaded')) {
        resolve()
    }
    if (shouldAppend) {      
      document.head.appendChild(s)
    }
  })
}

const initClient = (config)  =>  {
  return new Promise((resolve, reject) => {
    window.gapi.load('client:auth2', () => {
      window.gapi.client.init(config)
        .then(() => {
          resolve(window.gapi)
        }).catch((error) => {
          reject(error)
        })
    })
  })
}

const Google = class {
  constructor (config) {
    this.api = null
    this.gsiInitied = false
    this.apiInitied = false
    this.tokenClient = null
    this.config = config
  }

  load () {
    loadGsi().then(() => {
      this.tokenClient = window.google.accounts.oauth2.initTokenClient({
        client_id: this.config.clientId,
        scope: this.config.scope,
        callback: '',
      })

      this.gsiInitied = true
    })
    loadApi().then(() => {
      let config = {
        apiKey: this.config.apiKey,
        discoveryDocs: this.config.discoveryDocs
      }

      initClient(config)
        .then((api) => {
          this.api = api
          this.apiInitied = true
        })
    })
  }

  login() {
    return new Promise((resolve, reject) => {
      this.tokenClient.callback = (response) => {
        if (!response.access_token) {
          reject('Access token not found')
          return
        }

        resolve(response.access_token)
      }

      this.tokenClient.requestAccessToken({prompt: 'consent'})
    })
  }
}

export default {
  install: function (Vue, config) {
    const google = new Google(config)
    Vue.google = Vue.prototype.$google = google
    Vue.google.load()
  }
}