<template>
  <v-menu
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-avatar
        class="avatar"
        size="30"
        v-bind="attrs"
        v-on="on"
      >
        <v-img
          v-if="userData.avatar_url"
          :src="userData.avatar_url"
          :alt="firstLetter(userData.name)"
        ></v-img>
        <span 
          v-else
          class="secondary--text headline font-weight-medium"
        >{{ firstLetter(userData.name) }}</span>
      </v-avatar>
    </template>

    <v-list
      dense
    >
      <v-list-item>
        <v-list-item-avatar class="avatar list-avatar">
          <v-img
            v-if="userData.avatar_url"
            :src="userData.avatar_url"
            :alt="firstLetter(userData.name)"
          ></v-img>
          <span 
            v-else
            class="secondary--text headline font-weight-medium"
          >{{ firstLetter(userData.name) }}</span>
        </v-list-item-avatar>
        
        <v-list-item-content class="user-details">
          <v-list-item-title 
            class="title d-inline-block text-truncate"
          >
            {{ userData.name }}
          </v-list-item-title>

          <v-list-item-subtitle
            class="d-inline-block text-truncate"
          >{{ userData.email }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item
        link
        ripple
        @click="editProfile"
      >
        <v-list-item-icon>
          <v-icon color="defaultIconColor">mdi-account</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ $t('edit_profile') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        link
        ripple
        @click="logout"
      >
        <v-list-item-icon>
          <v-icon color="defaultIconColor">mdi-logout</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

    </v-list>

  </v-menu>
</template>

<style scoped>
  .avatar {
    background-color: var(--v-primary-base) !important;
  }

  .list-avatar {
    justify-content: center;
  }

  .user-details {
    max-width: 150px;
  }
</style>

<script>
import { mapState, mapActions } from 'vuex'

import AuthActions from '@/store/core/auth/actions-types'
import AuthMutations from '@/store/core/auth/mutations-types'
import router from '@/router'

export default {
  name: 'UserAvatarOptions',

  computed: mapState({
    authenticated: state => state.core.auth.authenticated,
    userData: state => state.core.auth.userData,
  }),

  methods: {
    ...mapActions('core/auth', [
      AuthActions.DO_LOGOUT,
    ]),

    editProfile() {
      router.push({name: 'UserProfile'})
    },

    logout() {
      this[AuthActions.DO_LOGOUT]()
        .then(() => {
          this.$store.commit(`core/auth/${AuthMutations.SET_LAST_PATH}`, null)
          router.push('/login')
        })
    },

    firstLetter: (name) => {
      return name.charAt(0).toUpperCase()
    },
  },
}
</script>