import ApplicationInstanceMutations from './mutations-types'

export default {

  [ApplicationInstanceMutations.SET_APPLICATION_INSTANCES](state, applicationsInstances) {
    state.applications_instances = applicationsInstances
  },

  [ApplicationInstanceMutations.SET_FIELD_APPLICATION](state, applications) {
    state.fields.find(f => f.name == 'application_id').items = applications
  },
  
  [ApplicationInstanceMutations.SET_SELECTED_APPLICATION_INSTANCE](state, application_instance) {
    state.selectedApplicationInstance = application_instance

    state.fields.forEach((field) => {
      field.value = null

      if (application_instance) {
        field.value = application_instance[field.name]
      }

      if (field.name == 'title') {
        field.autofocus = true

        if (application_instance) {
          field.autofocus = false
        }
      }
    })
  }
}