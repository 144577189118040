export default {
  REGISTER_BACKGROUND_ACTION: 'registerBackgroundAction',
  UPDATE_BACKGROUND_ACTION_PROGRESS: 'updateBackgroundActionProgress',
  UPDATE_BACKGROUND_ACTION_STATUS: 'updateBackgroundActionStatus',
  UPDATE_BACKGROUND_ACTION_TITLE: 'updateBackgroundActionTitle',
  REMOVE_BACKGROUND_ITEM: 'removeBackgroundItem',
  REMOVE_ALL_COMPLETED_BACKGROUND_ACTIONS: 'removeAllCompletedBackgroundActions',
  ADD_NEW_ACTIONS_NUMBER: 'addNewActionsNumber',
  SET_NEW_ACTIONS_NUMBER: 'setNewActionsNumber',
}
