<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 24 24">
    <rect class="cls-ae-2" x="2.4" y="3" width="19.2" height="18" rx="2.81" ry="2.81" />
    <g>
      <path class="cls-ae-1"
        d="M7.02,13.99l-.64,1.97c-.03,.07-.07,.11-.15,.11h-1.54c-.09,0-.12-.05-.11-.13l2.66-7.66c.05-.15,.11-.31,.13-.81,0-.05,.04-.09,.08-.09h2.13c.07,0,.09,.01,.11,.08l3.02,8.5c.03,.07,.01,.12-.07,.12h-1.73c-.08,0-.12-.03-.15-.09l-.68-1.98h-3.07Zm2.58-1.66c-.27-.88-.8-2.48-1.05-3.43h-.01c-.21,.89-.69,2.35-1.04,3.43h2.1Z" />
      <path class="cls-ae-1"
        d="M14.99,13.25c.07,.76,.6,1.38,1.9,1.38,.6,0,1.13-.09,1.63-.31,.04-.03,.08-.01,.08,.07v1.26c0,.09-.03,.13-.09,.16-.53,.25-1.12,.39-2.03,.39-2.5,0-3.37-1.71-3.37-3.36,0-1.83,1.13-3.51,3.21-3.51s2.8,1.55,2.8,2.87c0,.41-.03,.74-.07,.89-.01,.07-.04,.09-.11,.11-.17,.03-.68,.05-1.37,.05h-2.59Zm1.73-1.29c.42,0,.61-.01,.66-.03v-.09c0-.32-.25-1.05-1.13-1.05s-1.2,.62-1.28,1.17h1.74Z" />
    </g>
  </svg>
</template>

<style>
  .cls-ae-1 {
    fill: #99f;
  }

  .cls-ae-2 {
    fill: #00005b;
  }
</style>

<script>
  export default {
    props: {
      iconName: {
        type: String,
        default: 'adobe_after_effects'
      },
      width: {
        type: [Number, String],
        default: 24
      },
      height: {
        type: [Number, String],
        default: 24
      },
    }
  }
</script>