import i18n from '@/i18n.js'

export default {
  fields: [
    {
      name: 'name',
      type: 'text',
      label: 'Name',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      required: true,
      hide: false,
      value: null,
      replicateValToIdx: 1
    },
    {
      name: 'key',
      type: 'slug',
      label: 'Key',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      required: true,
      hide: false,
      replicable: true,
      value: null,
    },
    {
      name: 'cnpj',
      type: 'text',
      label: 'CNPJ',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      required: true,
      hide: false,
      value: null,
      mask: "##.###.###/####-##",
    },
    {
      name: 'tenant_id',
      type: 'select',
      label: 'Tenant',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      itemText: 'title',
      itemValue: 'tenant_id',
      items: [],
      required: true,
      hide: false,
      value: null
    },
    {
      name: 'active',
      type: 'switch',
      label: 'Active',
      value: true,
      hide: true,
    },
  ],

  tableHeaders: [
    {text: 'Key', value: 'key'},
    {text: 'Name', value: 'name'},
    {text: 'Tenant', value: 'tenant_id'},
    {text: 'CNPJ', value: 'cnpj'},
    {text: 'Actions', value: 'actions', sortable: false}
  ],

  companies: [],

  selectedCompany: null,

  inactiveFilterValue: false,
}
