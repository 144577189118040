import i18n from '@/i18n.js'

export default {
  fields: [
    {
      name: 'customer_property_attribute',
      type: 'text',
      label: 'Customer Property Attribute',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      autofocus: true,
      required: true,
      hide: false,
      value: null,
    },
    {
      name: 'media_attribute',
      type: 'text',
      label: 'Media Attribute',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      required: true,
      hide: false,
      value: null,
    },
    {
      name: 'media',
      type: 'select',
      label: 'Media',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      itemText: 'label',
      itemValue: 'val',
      items: [
        {
          label: 'None',
          val: 'none'
        },
        {
          label: 'Google',
          val: 'google'
        },
        {
          label: 'Facebook',
          val: 'facebook'
        },
      ],
      required: true,
      hide: false,
      value: null
    },
    {
      name: 'tenant_id',
      type: 'select',
      label: 'Tenant',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      itemText: 'title',
      itemValue: 'tenant_id',
      items: [],
      required: true,
      hide: false,
      value: null,
      changeEvent: 'tenant-change',
    },
    {
      name: 'customer_properties',
      type: 'select',
      label: 'Customer Properties',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      itemText: 'customer_property_title',
      itemValue: 'customer_property_id',
      items: [],
      required: true,
      hide: false,
      multiple: true,
      value: null,
    },
  ],

  attributeMappings: [],

  selectedAttributeMapping: null,
}
