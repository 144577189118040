<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 24 24">
    <rect class="clas-pre-2" x="2.4" y="3" width="19.2" height="18" rx="2.81" ry="2.81" />
    <g>
      <path class="clas-pre-1"
        d="M6.8,7.48c0-.08,.01-.11,.08-.11,.54-.01,1.59-.04,2.6-.04,2.68,0,3.61,1.49,3.61,2.94,0,2.15-1.66,3.07-3.71,3.07h-.76v2.62c0,.07-.03,.11-.11,.11h-1.63c-.07,0-.09-.04-.09-.11V7.48Zm1.83,4.22c.23,.01,.37,.01,.74,.01,1.05,0,1.89-.32,1.89-1.41,0-.84-.57-1.37-1.73-1.37-.42,0-.78,.01-.9,.03v2.74Z" />
      <path class="clas-pre-1"
        d="M15.66,9.46c.09,0,.13,.04,.16,.12,.07,.17,.13,.45,.15,.74,.44-.52,1.17-1,2.11-1,.08,0,.11,.03,.11,.11v1.63c0,.07-.03,.09-.12,.09-.96-.04-1.73,.25-2.01,.57v4.22c-.01,.08-.04,.11-.13,.11h-1.59c-.11,0-.13-.04-.13-.12v-4.58c0-.57-.01-1.34-.07-1.81,0-.07,.01-.09,.08-.09h1.45Z" />
    </g>
  </svg>
</template>

<style>
  .clas-pre-1 {
    fill: #99f;
  }

  .clas-pre-2 {
    fill: #00005b;
  }
</style>

<script>
  export default {
    props: {
      iconName: {
        type: String,
        default: 'adobe_premiere'
      },
      width: {
        type: [Number, String],
        default: 24
      },
      height: {
        type: [Number, String],
        default: 24
      },
    }
  }
</script>