<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 24 24">
    <rect class="clas-pp-2" x="2.95" y="3" width="18.11" height="18" rx="1.02" ry="1.02" />
    <path class="clas-pp-1"
      d="M10.11,13.29v3.41h-1.99V7.03h3.77c.73,0,1.36,.13,1.92,.4,.55,.27,.97,.64,1.27,1.13,.3,.49,.45,1.05,.45,1.67,0,.95-.32,1.69-.97,2.24-.65,.55-1.55,.82-2.69,.82h-1.75Zm0-1.61h1.78c.53,0,.93-.12,1.2-.37,.28-.25,.42-.6,.42-1.06s-.14-.86-.42-1.15c-.28-.29-.66-.44-1.15-.45h-1.83v3.03Z" />
  </svg>
</template>

<style>
  .clas-pp-1 {
    fill: #fff;
  }

  .clas-pp-2 {
    fill: #c5421f;
  }
</style>

<script>
  export default {
    props: {
      iconName: {
        type: String,
        default: 'office_power_point'
      },
      width: {
        type: [Number, String],
        default: 24
      },
      height: {
        type: [Number, String],
        default: 24
      },
    }
  }
</script>