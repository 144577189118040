import Vue from 'vue'
import Vuex from 'vuex'

import background_actions from './background_actions'
import app from './app'
import core from './core'
import operation from './operation'
import cdp from './cdp'
import content from './content'
import node from './node'

Vue.use(Vuex)

const store = {
  modules: {
    background_actions,
    app,
    core,
    operation,
    cdp,
    content,
    node,
  }
}

export default new Vuex.Store(store)
