import AuthActions from './actions-types'
import AuthMutations from './mutations-types'

import AppActions from '@/store/app/actions-types.js'

import api from '@/services/api'
import jwt from '@/services/jwt'

export default {
  [AuthActions.DO_LOGIN]({ dispatch }, credentials) {
    return new Promise((resolve, reject) => {

      api.core.auth
        .login(credentials)
        .then((response) => {
          dispatch(AuthActions.SAVE_LOGIN_DATA, response.data.token)
            .then(() => {
              resolve()
            })
            .catch((error) => reject(error))
        })
        .catch((error) => {
          reject(error.response.data);
        })
    })
  },

  [AuthActions.RENEW_TOKEN]({ state, dispatch }) {
    const params = {token: state.token}

    return new Promise((resolve, reject) => {
      api.core.auth
        .renewToken(params)
        .then((response) => {
          dispatch(AuthActions.SAVE_LOGIN_DATA, response.data.token)
            .then(() => {
              resolve()
            })
            .catch((error) => reject(error))
        })
        .catch((error) => {
          reject(error.response.data);
        })
    })
  },

  [AuthActions.DO_LOGOUT]({ dispatch }) {
    return new Promise((resolve) => {
      dispatch(AuthActions.RESET_LOGIN_DATA)

      resolve()      
    })
  },

  [AuthActions.SAVE_LOGIN_DATA]({ commit, dispatch }, token) {
    return new Promise((resolve, reject) => {
      jwt
        .decode(token)
        .then((decoded) => {
          commit(AuthMutations.SET_AUTHENTICATED, true)
          commit(AuthMutations.SET_TOKEN, token)
          commit(AuthMutations.SET_USER_DATA, decoded.data)
          commit(AuthMutations.SET_USER_ROLES)

          dispatch(`app/${[AppActions.GET_AND_SET_USER_APPLICATIONS]}`, {}, {root:true})
          .then(() => {
            resolve()
          })
          .catch((error) => {
            reject(error)  
          })
        })
        .catch((error) => {
          // @TODO: DISPATCH ALERT
          dispatch(AuthActions.RESET_LOGIN_DATA)

          reject(error)
        })
    })
  },

  [AuthActions.RESET_LOGIN_DATA]({ commit }) {
    commit(AuthMutations.SET_AUTHENTICATED, false)
    commit(AuthMutations.SET_TOKEN, null)
    commit(AuthMutations.SET_USER_DATA, {})
  },

  [AuthActions.CHECK_TOKEN]({ dispatch, state }) {
    return new Promise((resolve, reject) => {
      if (state.authenticated) {
        resolve()
        return
      }

      const token = localStorage.getItem('token')

      if (!token) {
        resolve()
        return
      }

      dispatch(AuthActions.SAVE_LOGIN_DATA, token)
        .then(() => resolve())
        .catch((error) => reject(error))
    })
  },

  [AuthActions.RESET_PASSWORD]({}, params) {
    return new Promise((resolve, reject) => {
      api.core.auth
        .resetPasswd( params )
        .then(() => {
          resolve()
        })
        .catch((error) => {
          reject(error.response.data);
        })
    })
  },
}
