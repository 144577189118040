<template>
  <svg version="1.1" id="Layer_1" :fill="iconColor" :width="width" :height="height" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
    <path d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3 M19,5v14H5V5H19z"/>
    <path d="M11.9,10c-0.9,0-1.7,0.8-1.7,1.7s0.8,1.7,1.7,1.7s1.7-0.8,1.7-1.7S12.9,10,11.9,10 M11.9,14.5c-1.6,0-2.8-1.3-2.8-2.8
      s1.3-2.8,2.8-2.8s2.8,1.3,2.8,2.8S13.5,14.5,11.9,14.5 M11.9,7.5c-2.8,0-5.2,1.8-6.2,4.2c1,2.5,3.4,4.2,6.2,4.2s5.2-1.8,6.2-4.2
      C17.2,9.2,14.8,7.5,11.9,7.5z"/>
  </svg>
</template>

<script>
  export default {
    props: {
      iconName: {
        type: String,
        default: 'checkbox_eye'
      },
      iconColor: {
        type: String,
        default: 'currentColor'
      },
      width: {
        type: [Number, String],
        default: 24
      },
      height: {
        type: [Number, String],
        default: 24
      },
    }
  }
</script>
