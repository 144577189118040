import AxiosContent from '@/services/api/content/axios_content'

export default {
  getAdminRepositories: (params) => {
    return AxiosContent.get('repository/admin', { params })
  },

  getAllRepositories: (params) => {
    return AxiosContent.get('repository', { params })
  },

  createRepository: (data) => {
    return AxiosContent.post('repository', data)
  },

  updateRepository: (data, id) => {
    return AxiosContent.put(`repository/${id}`, data)
  },

  configureGdrive: (id, data) => {
    return AxiosContent.post(`repository/${id}/gdrive_config`, data)
  },

  getGdriveConfig: (id) => {
    return AxiosContent.get(`repository/${id}/gdrive_config`)
  }
}
