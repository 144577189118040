import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import AuthActions from '@/store/core/auth/actions-types'
import AuthMutations from '@/store/core/auth/mutations-types'
import AppMutations from '@/store/app/mutations-types'

import coreRoutes from './core'
import operationRoutes from './operation'
import cdpRoutes from './cdp'
import contentRoutes from './content'

Vue.use(VueRouter)

const baseRoutes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      public: false
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/core/auth/Login.vue'),
    meta: {
      public: true,
      onlyLoggedOut: true
    }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('../views/core/auth/ResetPassword.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/Settings.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false
    }
  }
]

const routes = baseRoutes
  .concat(coreRoutes)
  .concat(operationRoutes)
  .concat(cdpRoutes)
  .concat(contentRoutes)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  store
    .dispatch('core/auth/' + AuthActions.CHECK_TOKEN)
    .then(() => {
      const authenticated = store.state.core.auth.authenticated
      const userRoles = store.state.core.auth.userRoles
      const resetPwd = store.state.core.auth.userData.reset_pwd || false
      const onlyLoggedOut = to.matched.some(record => record.meta.onlyLoggedOut)
      const isPublic = to.matched.some(record => record.meta.public)
      const accessRoles = to.meta.accessRoles || []
      const userApplications = store.state.app.userApplications

      if (!isPublic && !authenticated) {
        return next({
          path: '/login',
          query: { redirect: to.fullPath }
        })
      }

      if (resetPwd && to.fullPath !== "/reset-password") {
        return next({
          path: '/reset-password'
        })
      }

      if (authenticated && onlyLoggedOut) {
        return next('/')
      }

      if (accessRoles.length > 0) {
        const hasAccessRights = userRoles.some(r => accessRoles.includes(r))

        if (! hasAccessRights) {
          return next({
            path: '/',
            query: { redirect: to.fullPath }
          })
        }
      }

      if (to.matched.some(record => record.path.startsWith('/content'))) {
        const appId = to.params.appId

        const foundAppInstance = userApplications.some(app => app.application_instance_id == appId)
        if (! foundAppInstance) {
          return next('/')
        }

        store.commit(`app/${AppMutations.SET_SELECTED_CONTENT_APP}`, appId)
      }

      if (to.matched.some(record => record.path.startsWith('/cdp'))) {
        const appId = to.params.appId

        const foundAppInstance = userApplications.some(app => app.application_instance_id == appId)
        if (! foundAppInstance) {
          return next('/')
        }

        store.commit(`app/${AppMutations.SET_SELECTED_CDP_APP}`, appId)
      }

      if(to.name !== 'Login' && to.name !== 'ResetPassword') {
        store.commit(`core/auth/${AuthMutations.SET_LAST_PATH}`, to.fullPath)
      }

      next()
    })
    .catch(() => {
      console.log('Error checking token')
      return next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    })
})

export default router
