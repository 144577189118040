import AxiosContent from '@/services/api/content/axios_content'

export default {
  createGDoc: (params) => {
    return AxiosContent.post(`/google/create-gdoc`, params)
  },

  editGDoc: (nodeId) => {
    return AxiosContent.put(`/google/edit-gdoc/${nodeId}`)
  },

  finishEditingGDoc: (nodeId, params) => {
    return AxiosContent.put(`/google/finish-edit-gdoc/${nodeId}`, params)
  },

  convertToGDoc: (nodeId) => {
    return AxiosContent.post(`/google/convert-to-gdoc/${nodeId}`)
  },
}
