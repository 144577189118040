import CheckboxEye from '@/components/icons/CheckboxEye'
import CheckboxEyeOff from '@/components/icons/CheckboxEyeOff'
import KeynoteIcon from '@/components/icons/KeynoteIcon'

import OfficeWordIcon from '@/components/icons/Office/OfficeWordIcon'
import OfficePowerPointIcon from '@/components/icons/Office/OfficePowerPointIcon'
import OfficeExcelIcon from '@/components/icons/Office/OfficeExcelIcon'

import AdobeAfterEffectsIcon from '@/components/icons/Adobe/AdobeAfterEffectsIcon'
import AdobeAnimateIcon from '@/components/icons/Adobe/AdobeAnimateIcon'
import AdobeDreamweaverIcon from '@/components/icons/Adobe/AdobeDreamweaverIcon'
import AdobeIllustratorIcon from '@/components/icons/Adobe/AdobeIllustratorIcon'
import AdobeIndesignIcon from '@/components/icons/Adobe/AdobeIndesignIcon'
import AdobeLightroomIcon from '@/components/icons/Adobe/AdobeLightroomIcon'
import AdobePhotoshopIcon from '@/components/icons/Adobe/AdobePhotoshopIcon'
import AdobePremiereIcon from '@/components/icons/Adobe/AdobePremiereIcon'
import AdobeXdIcon from '@/components/icons/Adobe/AdobeXdIcon'

export default {
  checkbox_eye: {
    component: CheckboxEye
  },
  checkbox_eye_off: {
    component: CheckboxEyeOff
  },
  keynote: {
    component: KeynoteIcon
  },

  // office
  
  office_word: {
    component: OfficeWordIcon
  },
  office_power_point: {
    component: OfficePowerPointIcon
  },
  office_excel: {
    component: OfficeExcelIcon
  },

  // adobe

  adobe_after_effects: {
    component: AdobeAfterEffectsIcon
  },
  adobe_animate: {
    component: AdobeAnimateIcon
  },
  adobe_dreamweaver: {
    component: AdobeDreamweaverIcon
  },
  adobe_illustrator: {
    component: AdobeIllustratorIcon
  },
  adobe_indesign: {
    component: AdobeIndesignIcon
  },
  adobe_lightroom: {
    component: AdobeLightroomIcon
  },
  adobe_photoshop: {
    component: AdobePhotoshopIcon
  },
  adobe_premiere: {
    component: AdobePremiereIcon
  },
  adobe_xd: {
    component: AdobeXdIcon
  }
}
