import i18n from '@/i18n.js'

export default {
  security_groups: [],

  tableHeaders: [
    {text: 'Key', value: 'security_group_key'},
    {text: 'Tenant', value: 'tenant_id'},
    {text: 'Match Type', value: 'match_type'},
    {text: 'Propagation Policy', value: 'propagation_policy'},
    {text: 'Node Cardinality', value: 'node_cardinality'},
    {text: 'User Cardinality', value: 'user_cardinality'},
    {text: 'Actions', value: 'actions', sortable: false}
  ],

  selectedSecurityGroup: {},

  fields: [
    {
      name: 'security_group_key',
      type: 'text',
      label: 'Key',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      autofocus: true,
      required: true,
      hide: false,
      value: null
    },
    {
      name: 'tenant_id',
      type: 'select',
      label: 'Tenant',
      items: [],
      itemText: 'title',
      itemValue: 'tenant_id',
      required: true,
      hide: false,
      chips: true,
      multiple: false
    },
    {
      name: 'match_type',
      type: 'select',
      label: 'Match_Type',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      itemText: 'label',
      itemValue: 'val',
      items: [
        {
          label: 'And',
          val: 'and'
        },
        {
          label: 'Or',
          val: 'or'
        },
        {
          label: 'Tree',
          val: 'tree'
        }
      ],
      required: true,
      hide: false,
      value: null
    },
    {
      name: 'propagation_policy',
      type: 'select',
      label: 'Propagation',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      itemText: 'label',
      itemValue: 'val',
      items: [
        {
          label: 'nevercopy',
          val: 'nevercopy',
        },
        {
          label: 'inherited',
          val: 'inherited'
        }
      ],
      required: true,
      hide: false,
      value: null
    },
    {
      name: 'node_cardinality',
      type: 'select',
      label: 'Node Cardinality',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      itemText: 'label',
      itemValue: 'val',
      dependencyFieldKey: 'match_type',
      itemsToFilter: [
        {
          label: 'single',
          val: 'single',
          dependencyVals: ['and', 'or']
        },
        {
          label: 'multiple',
          val: 'multiple',
          dependencyVals: ['and', 'or', 'tree']
        }
      ],
      items: [],
      required: true,
      hide: false,
      value: null
    },
    {
      name: 'user_cardinality',
      type: 'select',
      label: 'User Cardinality',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      itemText: 'label',
      itemValue: 'val',
      dependencyFieldKey: 'match_type',
      itemsToFilter: [
        {
          label: 'single',
          val: 'single',
          dependencyVals: ['and', 'or']
        },
        {
          label: 'multiple',
          val: 'multiple',
          dependencyVals: ['and', 'or', 'tree']
        }
      ],
      items: [],
      required: true,
      hide: false,
      value: null
    }
  ]
}
