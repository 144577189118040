import AxiosContent from '@/services/api/content/axios_content'

export default {
  create: (nodeId, name, file) => {
    let formData = new FormData();

    formData.append('name', name)
    formData.append('file', file)

    return AxiosContent.post(`/self_service_template/${nodeId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  },

  get: (nodeId) => {
    return AxiosContent.get(`/self_service_template/${nodeId}`)
  },

  generatePreview: (nodeId, data) => {
    return AxiosContent.post(`/self_service_template/generate/render/${nodeId}`, data)
  },

  getHistory: (nodeId) => {
    return AxiosContent.get(`/self_service_template/history/${nodeId}`)
  },

  getHistoricalData: (jstNodeId, generationId) => {
    return AxiosContent.get(`/self_service_template/history/${jstNodeId}/data/${generationId}`)
  },

  generateRaster: (nodeId, data) => {
    return AxiosContent.post(`/self_service_template/generate/raster/${nodeId}`, data)
  }
}