import AxiosCdp from '@/services/api/cdp/axios_cdp'

export default {
  getAll: (params) => {
    return AxiosCdp.get('cdp/profileview', {params})
  },

  createProfileView: (data) => {
    return AxiosCdp.post('cdp/profileview_with_property', data)
  },

  updateProfileView: (data, id) => {
    return AxiosCdp.put(`cdp/profileview/${id}`, data)
  },

  createProfileViewExport: (data) => {
    return AxiosCdp.post('cdp/profileview/export', data)
  },
}
