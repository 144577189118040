import EnablerMutations from './mutations-types'

export default {
  [EnablerMutations.SET_ENABLERS](state, enablers) {
    state.enablers = [...enablers]
  },

  [EnablerMutations.SET_SELECTED_ENABLER](state, enabler) {
    state.selectedEnabler = enabler

    state.fields.forEach((field) => {
      field.value = null

      if (enabler) {
        field.value = enabler[field.name]
      }
    })
  },

  [EnablerMutations.SET_FIELD_TENANTS](state, tenants) {
    state.fields.find(f => f.name == 'tenant_id').items = tenants
  },

  [EnablerMutations.SET_FIELD_TYPES](state, type) {
    state.fields.find(f => f.name == 'enabler_type').items = type
  },

  [EnablerMutations.SET_FIELD_PARAMS](state, params) {
    state.fields.find(f => f.name == 'enabled_params').items = params
  }
}