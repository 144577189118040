import customer_property from './customer_property'
import profile_view from './profile_view'
import unomi_config from './unomi_config'
import attribute_mapping from './attribute_mapping'
import unomi_console from './unomi_console'

export default {
  namespaced: true,
  modules: {
    customer_property,
    profile_view,
    unomi_config,
    attribute_mapping,
    unomi_console,
  }
}
