import UserMutations from './mutations-types'

export default {

  [UserMutations.SET_FIELD_TENANTS](state, payload) {
    state.fields.find(f => f.name == 'tenant_id').items = payload
    state.tenants = [...payload]
  },

  [UserMutations.SET_USER_GROUPS](state, payload) {
    state.groups = [...payload]
  },

  [UserMutations.SET_SELECTED_USER](state, user) {
    state.selectedUser = user

    state.fields.forEach((field) => {
      if (field.name in user) {
        field.value = user[field.name]
      }
    })
  },

  [UserMutations.SET_USERS](state, users) {
    state.users = [...users]
  },

  [UserMutations.SET_INACTIVE_FILTER_VAL](state, active) {
    state.inactiveFilterValue = active
  }
}