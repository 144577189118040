<template>
  <v-list-item
    ripple
  >
    <v-list-item-icon class="mx-0">
      <v-icon>{{ data.icon }}</v-icon>
    </v-list-item-icon>

    <v-divider
      class="mx-4"
      vertical
    ></v-divider>

    <v-list-item-content>
      <v-list-item-title>{{ `${data.title} (${progress})` }}</v-list-item-title>

      <v-list-item-subtitle>{{ subtitle }}</v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-action>
      <v-btn
        v-if="data.status == 'in_progress'"
        icon
        @click.stop="cancel"
      >
        <v-icon color="defaultIconColor">mdi-close-circle</v-icon>
      </v-btn>

      <v-icon
        v-else
        small
        :color="data.status"
      >{{ statusIcon }}</v-icon>

    </v-list-item-action>

  </v-list-item>
</template>

<style scoped>
  
</style>

<script>

import { mapActions } from 'vuex'

import BackgroundActions from '@/store/background_actions/actions-types.js'

export default {
  name: 'BackgroundActionItem',

  props: {
    data: {
      type: Object
    }
  },

  computed: {
    progress() {
      if (this.data.status != 'in_progress') {
        return this.$t(this.data.status)
      }

      return `${this.data.progress}%`
    },

    subtitle() {
      return `${this.$t('started')} - ${this.data.createdAt.getHours()}:${String(this.data.createdAt.getMinutes()).padStart(2, '0')}`
    },

    statusIcon() {
      let icon = ''
      switch(this.data.status) {
        case 'success':
          icon = 'mdi-check-circle'
          break
        case 'error':
          icon = 'mdi-alert-circle'
          break
        default:
          icon = 'mdi-information'
      }

      return icon
    },
  },

  methods: {
    ...mapActions('background_actions', [
      BackgroundActions.CANCEL_BACKGROUND_ACTION,
    ]),

    cancel() {
      this[BackgroundActions.CANCEL_BACKGROUND_ACTION](this.data)
    },
  }
}

</script>