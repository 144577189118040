<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 24 24">
    <rect class="clas-xd-2" x="2.4" y="3" width="19.2" height="18" rx="2.81" ry="2.81" />
    <g>
      <path class="clas-xd-1"
        d="M9.99,11.55l2.63,4.34c.04,.07,.01,.13-.07,.15h-1.89c-.12,0-.19-.04-.23-.12-.58-1.04-1.17-2.07-1.77-3.16h-.01c-.53,1.06-1.12,2.14-1.67,3.17-.04,.08-.09,.11-.19,.11h-1.79c-.09,0-.09-.08-.05-.13l2.55-4.22-2.5-4.21c-.05-.07,0-.13,.07-.13h1.87c.09,0,.13,.01,.17,.11,.53,1.05,1.16,2.1,1.7,3.15h.01c.52-1.06,1.1-2.09,1.65-3.15,.04-.07,.07-.11,.19-.11h1.71c.09,0,.11,.07,.05,.15l-2.44,4.06Z" />
      <path class="clas-xd-1"
        d="M12.78,12.8c0-1.9,1.42-3.49,3.85-3.49,.11,0,.24,.01,.44,.03v-2.62c0-.07,.04-.09,.09-.09h1.67c.07,0,.08,.03,.08,.08v7.85c0,.31,.01,.7,.05,.97,0,.07-.01,.09-.09,.12-.92,.39-1.79,.53-2.62,.53-1.97,0-3.48-1.12-3.48-3.37Zm4.29-1.89c-.13-.05-.31-.07-.49-.07-1.02,0-1.9,.62-1.9,1.86,0,1.32,.76,1.89,1.77,1.89,.23,0,.44-.01,.62-.08v-3.6Z" />
    </g>
  </svg>
</template>

<style>
  .clas-xd-1 {
    fill: #ff61f6;
  }

  .clas-xd-2 {
    fill: #470137;
  }
</style>

<script>
  export default {
    props: {
      iconName: {
        type: String,
        default: 'adobe_xd'
      },
      width: {
        type: [Number, String],
        default: 24
      },
      height: {
        type: [Number, String],
        default: 24
      },
    }
  }
</script>