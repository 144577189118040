import TenantMutations from './mutations-types'

export default {
  [TenantMutations.SET_FIELD_APPLICATIONS_INSTANCES](state, payload) {
    state.fields.find(f => f.name == 'app_instance_ids').items = payload
  },

  [TenantMutations.SET_SELECTED_TENANT](state, tenant) {
    state.selectedTenant = tenant

    state.fields.forEach((field) => {
      field.value = null

      if (tenant) {
        field.value = tenant[field.name]
      }

      if (field.name == 'title') {
        field.autofocus = true

        if (tenant) {
          field.autofocus = false
        }
      }

      if (field.name == 'key') {
        field.disabled = false
        field.replicable = true

        if (tenant) {
          field.disabled = true
          field.replicable = false
        }
      }

      if (field.name == 'cnpj') {
        field.hide = false

        if (tenant) {
          field.hide = true
        }
      }

      if (field.name == 'active') {
        if (tenant) {
          field.hide = false

          return
        }

        field.value = true
        field.hide = true
      }

      if (field.name == 'theme') {
        if (tenant) {
          field.value = tenant.theme_id || null

          return
        }

        field.value = null
      }
    })
  },

  [TenantMutations.SET_TENANTS](state, tenants) {
    state.tenants = [...tenants]
  },

  [TenantMutations.SET_INACTIVE_FILTER_VAL](state, active) {
    state.inactiveFilterValue = active
  },

  [TenantMutations.SET_SELECTED_TENANT_QUOTA](state, quota) {
    state.selectedQuota = quota

    state.quotaFields.forEach((field) => {

      field.value = null

      if (quota) {
        field.value = quota[field.name]
      }

      if (field.name == 'service') {
        quota ? field.hide = true : field.hide = false
      }

      if (field.name == 'balance_limit') {
        quota ? field.hide = false : field.hide = true
      }

      if (field.name == 'monthly_credit') {
        quota ? field.hide = false : field.hide = true
      }

    })
  }
}