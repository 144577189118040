<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 24 24">
    <rect class="clas-ill-2" x="2.4" y="3" width="19.2" height="18" rx="2.81" ry="2.81" />
    <g>
      <path class="clas-ill-1"
        d="M8.54,14.09l-.64,1.97c-.03,.07-.07,.11-.15,.11h-1.54c-.09,0-.12-.05-.11-.13l2.66-7.66c.05-.15,.11-.31,.13-.81,0-.05,.04-.09,.08-.09h2.13c.07,0,.09,.01,.11,.08l3.02,8.5c.03,.07,.01,.12-.07,.12h-1.73c-.08,0-.12-.03-.15-.09l-.68-1.98h-3.07Zm2.58-1.66c-.27-.88-.8-2.48-1.05-3.43h-.01c-.21,.89-.69,2.35-1.04,3.43h2.1Z" />
      <path class="clas-ill-1"
        d="M15.02,7.82c0-.6,.44-1.02,1.04-1.02,.64,0,1.02,.43,1.02,1.02s-.41,1.04-1.04,1.04-1.02-.41-1.02-1.04Zm.09,1.85c0-.07,.03-.11,.11-.11h1.63c.08,0,.11,.03,.11,.11v6.38c0,.08-.01,.12-.12,.12h-1.59c-.09,0-.13-.04-.13-.12v-6.38Z" />
    </g>
  </svg>
</template>

<style>
  .clas-ill-1 {
    fill: #ff9a00;
  }

  .clas-ill-2 {
    fill: #300;
  }
</style>

<script>
  export default {
    props: {
      iconName: {
        type: String,
        default: 'adobe_illustrator'
      },
      width: {
        type: [Number, String],
        default: 24
      },
      height: {
        type: [Number, String],
        default: 24
      },
    }
  }
</script>