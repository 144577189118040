import i18n from '@/i18n.js'

export default {
  fields: [
    {
      name: 'customer_property_title',
      type: 'text',
      label: 'Title',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      autofocus: true,
      required: true,
      hide: false,
      value: null,
      replicateValToIdx: 1
    },
    {
      name: 'customer_property_key',
      type: 'slug',
      label: 'Key',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      required: true,
      hide: false,
      replicable: true,
      value: null,
    },
    {
      name: 'tenant_id',
      type: 'select',
      label: 'Tenant',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      itemText: 'title',
      itemValue: 'tenant_id',
      items: [],
      required: true,
      hide: false,
      value: null
    },
    {
      name: 'type',
      type: 'select',
      label: 'Type',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      itemText: 'label',
      itemValue: 'val',
      items: [
        {
          label: 'Website',
          val: 'website'
        },
        {
          label: 'App',
          val: 'app'
        },
      ],
      required: true,
      hide: false,
      value: null
    },
    {
      name: 'active',
      type: 'switch',
      label: 'Active',
      value: true,
      hide: true,
    },
  ],

  customerProperties: [],

  selectedCustomerProperty: null,

  inactiveFilterValue: false
}
