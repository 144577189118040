import i18n from '@/i18n.js'

export default {
  fields: [
    {
      name: 'unomi_id',
      type: 'text',
      label: 'Unomi Id',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      autofocus: true,
      required: true,
      hide: false,
      value: null
    },
    {
      name: 'description',
      type: 'text',
      label: 'Description',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      required: true,
      hide: false,
      value: null
    },
    {
      name: 'version',
      type: 'number',
      label: 'Version',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      required: true,
      hide: false,
      value: null,
    },
    {
      name: 'code',
      type: 'textarea',
      label: 'Code',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      required: true,
      hide: false,
      value: null,
    },
    {
      name: 'tenant_id',
      type: 'select',
      label: 'Tenant',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      itemText: 'title',
      itemValue: 'tenant_id',
      items: [],
      required: true,
      hide: false,
      value: null
    },
    {
      name: 'type',
      type: 'select',
      label: 'Type',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      itemText: 'label',
      itemValue: 'val',
      items: [
        {
          label: 'Rule',
          val: 'rules'
        },
        {
          label: 'Segment',
          val: 'segments'
        },
        {
          label: 'Action',
          val: 'actions'
        },
        {
          label: 'Campaign',
          val: 'campaign'
        },
        {
          label: 'Condition',
          val: 'conditions'
        },
        {
          label: 'Goals',
          val: 'goals'
        },
        {
          label: 'Scoring',
          val: 'scoring'
        },
      ],
      required: true,
      hide: false,
      value: null
    },
    {
      name: 'customer_properties',
      type: 'select',
      label: 'Customer properties',
      multiple: true,
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      itemText: 'customer_property_title',
      itemValue: 'customer_property_id',
      items: [],
      required: true,
      hide: false,
      value: null
    }
  ],

  unomiConfigs: [],

  selectedUnomiConfig: null
}
