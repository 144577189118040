import i18n from '@/i18n.js'

export default {
  fields: [
    {
      name: 'title',
      type: 'text',
      label: 'Title',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      required: true,
      hide: false,
      value: null,
    },
    {
      name: 'folder_name',
      type: 'text',
      label: 'Folder Name',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      required: true,
      hide: false,
      value: null,
    },
    {
      name: 'file_server_id',
      type: 'select',
      label: 'File Server',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      itemText: 'label',
      itemValue: 'file_server_id',
      items: [],
      required: true,
      hide: false,
      value: null,
      changeEvent: 'file-server-change',
    },
    {
      name: 'tenant_id',
      type: 'select',
      label: 'Tenant',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      itemText: 'title',
      itemValue: 'tenant_id',
      items: [],
      required: true,
      hide: false,
      value: null,
    },
    {
      name: 'lib3d_node_id',
      type: 'text',
      label: 'Lib3D Node ID',
      required: false,
      rules: [
        v => Number.isInteger(Number(v)) || i18n.t('form_rules.number')
      ],
      hide: false,
      value: null,
    },
    {
      name: 'active',
      type: 'switch',
      label: 'Active',
      value: true,
      hide: true,
    },
  ],

  repositories: [],

  selectedRepository: null,

  inactiveFilterValue: false,
}
