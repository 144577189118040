import UserRoles from '@/store/core/user/user-roles'

export default [
  {
    path: '/content/:appId/settings',
    name: 'ContentSettings',
    component: () => import('../views/content/settings/ContentSettings.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false,
      access_role: [
        UserRoles.USER_ADMIN,
      ]
    }
  },
  {
    path: '/content/:appId/settings/file_server',
    name: 'FileServer',
    component: () => import('../views/content/file_server/FileServer.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false,
      access_role: [
        UserRoles.USER_ADMIN,
      ]
    }
  },
  {
    path: '/content/:appId/settings/repository',
    name: 'Repository',
    component: () => import('../views/content/repository/Repository.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false,
      access_role: [
        UserRoles.USER_ADMIN,
      ]
    }
  },
  {
    path: '/content/:appId/navigation/home',
    name: 'ContentHome',
    component: () => import('../views/content/navigation/ContentHome.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false
    }
  },
  {
    path: '/content/:appId/navigation/:parentId',
    name: 'ContentNavigation',
    component: () => import('../views/content/navigation/Navigation.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false
    }
  },
  {
    path: '/content/:appId/actions/entry/info/:nodeId',
    name: 'EntryInfo',
    component: () => import('../views/content/actions/EntryInfo.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false
    }
  },
  {
    path: '/content/:appId/aspects/actionsets/:nodeId',
    name: 'AttachActionsets',
    component: () => import('../views/content/aspects/AttachActionsets.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false
    }
  },
  {
    path: '/content/:appId/aspects/enabler/:nodeId',
    name: 'AttachEnablers',
    component: () => import('../views/content/aspects/AttachEnablers.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false
    }
  },
  {
    path: '/content/:appId/aspects/security_group/:nodeId',
    name: 'AttachSecurityGroups',
    component: () => import('../views/content/aspects/AttachSecurityGroups.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false
    }
  },
  {
    path: '/content/:appId/google/edit_gdoc/:nodeId',
    name: 'EditGDoc',
    component: () => import('../views/content/actions/EditGDoc.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false
    }
  },
  {
    path: '/content/:appId/synthetic/edit/:nodeId',
    name: 'EditSyntheticMedia',
    component: () => import('../views/content/synthetic/EditSyntheticMedia.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false
    }
  },
  {
    path: '/content/:appId/self_service_template/edit/:nodeId',
    name: 'EditJst',
    component: () => import('../views/content/self_service_template/EditJst.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false
    }
  },
];
