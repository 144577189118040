import api from '@/services/api'
import GrantActions from './actions-types'

export default {
  [GrantActions.GET_GRANTS_BY_NODE]({}, nodeId) {
    return new Promise((resolve, reject) => {
      api.node.grant
        .getGrantsByNode(nodeId)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => reject(error))
    })
  },
  [GrantActions.GET_GRANTS_BY_NODE_WITH_PARENTS]({}, nodeId) {
    return new Promise((resolve, reject) => {
      api.node.grant
        .getGrantsByNodeWithParents(nodeId)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => reject(error))
    })
  },
  [GrantActions.ADD_GRANT]({}, options) {
    return new Promise((resolve, reject) => {
      api.node.grant
        .addGrant(options)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => reject(error))
    })
  },

  [GrantActions.DELETE_GRANT]({}, nodeId) {
    return new Promise((resolve, reject) => {
      api.node.grant
        .deleteGrant(nodeId)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => reject(error))
    })
  },
}