import AxiosNode from '@/services/api/node/axios_node'

export default {
  getFilters: (params) => {
    return AxiosNode.get('/filter', {params})
  },

  getFilterFull: (filterId) => {
    return AxiosNode.get(`/filter/${filterId}/with_conditions`)
  },

  createFilter: (data) => {
    return AxiosNode.post('filter', data)
  },

  saveFilterFull: (filterId, data) => {
    return AxiosNode.put(`/filter/${filterId}/with_conditions`, data)
  },
}