import api from '@/services/api'
import FilterActions from './actions-types'

export default {
  [FilterActions.GET_ALL_FILTERS]({}, options) {
    return new Promise((resolve, reject) => {
      api.node.filter
        .getFilters(options)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => reject())
    })
  },

  [FilterActions.GET_FILTER_FULL]({}, filterId) {
    return new Promise((resolve, reject) => {
      api.node.filter
        .getFilterFull(filterId)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => reject())
    })
  },

  [FilterActions.SAVE_FILTER]({ state }) {
    return new Promise((resolve, reject) => {
      let data = {}
      state.fields.forEach((item) => {
        data[item.name] = item.value
      })

      api.node.filter
        .createFilter(data)
        .then((response) => {
          resolve(response.body)
        })
        .catch((error) => {
          reject(error.response.data)
        })
    })
  },

  [FilterActions.SAVE_FILTER_FULL]({}, {filterId, params}) {
    return new Promise((resolve, reject) => {
      api.node.filter
        .saveFilterFull(filterId, params)
        .then((response) => {
          resolve(response.body)
        })
        .catch((error) => {
          reject(error.response.data)
        })
    })
  },
}
