import api from '@/services/api'
import NotificationActions from './actions-types'

export default {
  [NotificationActions.SEND_MESSAGE]({}, options) {
    return new Promise((resolve, reject) => {
      api.core.notification
        .sendMessage(options)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => reject(error))
    })
  },
  [NotificationActions.SEND_SHARE_MESSAGE]({}, options) {
    return new Promise((resolve, reject) => {
      api.core.notification
        .sendShareMessage(options)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => reject(error))
    })
  },

}