import i18n from '@/i18n.js'

export default {
  enablers: [],

  tableHeaders: [
    {text: 'Key', value: 'aspect_key'},
    {text: 'Tenant', value: 'tenant_id'},
    {text: 'Type', value: 'enabler_type'},
    {text: 'Actions', value: 'actions', sortable: false}
  ],

  selectedEnabler: {},

  fields: [
    {
      name: 'aspect_key',
      type: 'text',
      label: 'Key',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      autofocus: true,
      required: true,
      hide: false,
      value: null
    },
    {
      name: 'tenant_id',
      type: 'select',
      label: 'Tenant',
      items: [],
      itemText: 'title',
      itemValue: 'tenant_id',
      required: true,
      hide: false,
      chips: true,
      multiple: false
    },
    {
      name: 'enabler_type',
      type: 'text',
      label: 'Type',
      required: true,
      hide: false,
      value: null,
    },
    {
      name: 'enabler_params',
      type: 'textarea',
      label: 'Parameters (json)',
      required: true,
      hide: false,
      value: null,
    }
  ]
}
