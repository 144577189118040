import application_instance from './application_instance'
import auth from './auth'
import theme from './theme'
import tenant from './tenant'
import company from './company'
import user from './user'
import group from './group'
import notification from './notification'

export default {
  namespaced: true,
  modules: {
    application_instance,
    auth,
    theme,
    tenant,
    company,
    user,
    group,
    notification,
  }
}
