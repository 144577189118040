import UserRoles from '@/store/core/user/user-roles'

export default [
  {
    path: '/user/profile',
    name: 'UserProfile',
    component: () => import('../views/core/user/UserProfile.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false,
      accessRoles: [
        UserRoles.USER_REGULAR
      ]
    },
  },
  {
    path: '/settings/core/tenant',
    name: 'Tenant',
    component: () => import('../views/core/tenant/Tenant.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false,
      accessRoles: [
        UserRoles.USER_MASTER,
        UserRoles.USER_ADMIN
      ]
    }
  },
  {
    path: '/settings/core/application_instance',
    name: 'ApplicationInstance',
    component: () => import('../views/core/application_instance/ApplicationInstance.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false
    }
  },
  {
    path: '/settings/core/tenant/:tenantId/admin',
    name: 'TenantAdmin',
    component: () => import('../views/core/tenant/TenantAdmin.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false,
      accessRoles: [
        UserRoles.USER_MASTER,
        UserRoles.USER_ADMIN
      ]
    }
  },
  {
    path: '/settings/core/tenant/:tenantId/quotas',
    name: 'TenantQuotas',
    component: () => import('../views/core/tenant/TenantQuotas.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false,
      accessRoles: [
        UserRoles.USER_MASTER,
        UserRoles.USER_ADMIN
      ]
    }
  },
  {
    path: '/settings/core/master_user',
    name: 'MasterUser',
    component: () => import('../views/core/master_user/MasterUser.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false,
      accessRoles: [UserRoles.USER_MASTER]
    }
  },
  {
    path: '/settings/core/company',
    name: 'Company',
    component: () => import('../views/core/company/Company.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false,
      accessRoles: [
        UserRoles.USER_MASTER,
        UserRoles.USER_ADMIN
      ]
    }
  },
  {
    path: '/settings/core/user',
    name: 'User',
    component: () => import('../views/core/user/User.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false
    }
  },
  {
    path: '/settings/core/group',
    name: 'Group',
    component: () => import('../views/core/group/Group.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false,
      accessRoles: [
        UserRoles.USER_ADMIN,
        UserRoles.USER_MANAGER
      ]
    },
  },
  {
    path: '/settings/core/company/:companyId/group/:groupId/users/:originTenant?',
    name: 'GroupUsers',
    component: () => import('../views/core/group/GroupUsers.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false,
      accessRoles: [
        UserRoles.USER_ADMIN,
        UserRoles.USER_MANAGER
      ]
    },
  },
  {
    path: '/settings/core/group/:groupId/external_companies',
    name: 'ExternalCompanies',
    component: () => import('../views/core/group/ExternalCompanies.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false,
      accessRoles: [
        UserRoles.USER_ADMIN,
        UserRoles.USER_MANAGER
      ]
    },
  },
  {
    path: '/settings/core/theme',
    name: 'Theme',
    component: () => import('../views/core/theme/Theme.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false,
      accessRoles: [
        UserRoles.USER_MASTER,
        UserRoles.USER_ADMIN,
      ]
    },
  },
]
