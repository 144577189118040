import api from '@/services/api'
import ProfileViewActions from './actions-types'
import ProfileViewMutations from './mutations-types'

export default {

  [ProfileViewActions.GET_ALL]({ commit }, options = {}) {
    return new Promise((resolve, reject) => {
      api.cdp.profile_view
          .getAll(options)
          .then((response) => {
            commit(ProfileViewMutations.SET_PROFILE_VIEWS, response.data.items)
            resolve(response.data)
          })
          .catch(() => reject())
    })
  },

  [ProfileViewActions.SAVE_PROFILE_VIEW]({ state }) {
    return new Promise((resolve, reject) => {
      let data = {}
      
      state.fields.forEach((item) => {
        let fieldName = item.name

        if (fieldName == 'customer_properties') {
          fieldName = 'customer_property_ids'
        }

        if (fieldName == 'user_groups') {
          fieldName = 'user_group_ids'
        }

        data[fieldName] = item.value
      })

      if (state.selectedProfileView) {
        api.cdp.profile_view
          .updateProfileView(data, state.selectedProfileView.profile_view_id)
          .then((response) => {
            resolve(response.body)
          })
          .catch(() => reject())
      } else {
        api.cdp.profile_view
          .createProfileView(data)
          .then((response) => {
            resolve(response.body)
          })
          .catch(() => reject())
      }
    })
  },

  [ProfileViewActions.CREATE_PROFILE_VIEW_EXPORT]({ state }) {
    return new Promise((resolve, reject) => {
      let data = {
        profile_view_id: state.selectedProfileView.profile_view_id
      }
      
      state.exportFields.forEach((item) => {
        data[item.name] = item.value
      })

      api.cdp.profile_view
          .createProfileViewExport(data)
          .then((response) => {
            resolve(response.data)
          })
          .catch(() => reject())
    })
  },
}