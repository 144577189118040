import api from '@/services/api'
import FieldActions from './actions-types'

export default {
  [FieldActions.GET_TENANT_FIELDS]({}, tenantId) {
    return new Promise((resolve, reject) => {
      api.node.field
        .getTenantFields(tenantId)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => reject())
    })
  },
}
