import AxiosNode from '@/services/api/node/axios_node'

export default {
  getActionsets: (data) => {
    return AxiosNode.get('/actionset', {params: data})
  },

  createActionset: (data) => {
    return AxiosNode.post('actionset', data)
  },

  updateActionset: (data, id) => {
    return AxiosNode.put('actionset/' + id, data)
  },

  getActionsetsByNode: (nodeId) => {
    return AxiosNode.get('actionset/node/' + nodeId)
  },  
}