import api from '@/services/api'
import ClearanceActions from './actions-types'

export default {
  [ClearanceActions.GET_CLEARANCE_BY_GROUP]({}, groupId) {
    return new Promise((resolve, reject) => {
      api.node.clearance
        .getClearanceByGroup(groupId)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => reject())
    })
  },

  [ClearanceActions.GET_USER_GROUPS_CLEARANCES]({}) {
    return new Promise((resolve, reject) => {
      api.node.clearance
        .getUserGroupsClearances()
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => reject())
    })
  },

  [ClearanceActions.SAVE_SECURITY_CLEARANCE]({}, clearance) {
    return new Promise((resolve, reject) => {
      api.node.clearance
        .saveSecurityClearance(clearance)
        .then(() => {
          resolve()
        })
        .catch(() => reject())
    })
  }
}
