import i18n from '@/i18n.js'

export default {
  fields: [
    {
      name: 'label',
      type: 'text',
      label: 'Label',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      required: true,
      hide: false,
      value: null,
    },
    {
      name: 'url',
      type: 'text',
      label: 'Url',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      required: true,
      hide: false,
      value: null,
    },
    {
      name: 'type',
      type: 'select',
      label: 'Type',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      itemText: 'label',
      itemValue: 'val',
      items: [
        {
          label: 'Native',
          val: 'posix'
        },
      ],
      required: true,
      hide: false,
      value: 'native'
    },
    {
      name: 'tenant_ids',
      type: 'select',
      label: 'Tenant',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      itemText: 'title',
      itemValue: 'tenant_id',
      items: [],
      required: true,
      multiple: true,
      hide: false,
      value: null,
    },
    {
      name: 'external_access_address',
      type: 'text',
      label: 'External access address',
      required: false,
      hide: false,
      value: null,
    },
    {
      name: 'queue_server_address',
      type: 'text',
      label: 'Queue server address',
      required: false,
      hide: false,
      value: null,
    },
    {
      name: 'queue_id',
      type: 'text',
      label: 'Queue id',
      required: false,
      hide: false,
      value: null,
    },
    {
      name: 'cdn_domain',
      type: 'text',
      label: 'CDN Domain',
      required: false,
      hide: false,
      value: null,
    },
    {
      name: 'active',
      type: 'switch',
      label: 'Active',
      value: true,
      hide: true,
    },
  ],

  fileServers: [],

  selectedFileServer: null,

  inactiveFilterValue: false,
}
