import GroupMutations from './mutations-types'
import Vue from 'vue'

export default {
  [GroupMutations.SET_FIELD_TENANT](state, tenants) {
    state.fields.forEach((el, idx) => {
      if (el.name == 'tenant_id') {
        Vue.set(state.fields[idx], 'items', tenants)

        return
      }
    })
  },

  [GroupMutations.SET_FIELD_COMPANY](state, companies) {
    state.fields.find(f => f.name == 'company_id').items = companies
  },

  [GroupMutations.SET_FIELD_APPLICATIONS](state, applications) {
    state.fields.find(f => f.name == 'application_ids').items = applications
  },

  [GroupMutations.SET_FIELD_APPLICATION_INSTANCES](state, applications_instances) {
    state.fields.find(f => f.name == 'application_instance_ids').items = applications_instances
  },

  [GroupMutations.SET_SELECTED_GROUP](state, group) {
    state.selectedGroup = group

    state.fields.forEach((field) => {
      field.value = null
      field.readonly = false

      if (field.name == 'group_title') {
        field.autofocus = true

        if (group) {
          field.value = group.group_title
        }
      }

      if (field.name == 'group_key') {
        field.disabled = false
        field.replicable = true

        if (group) {
          field.value = group.group_key

          field.disabled = true
          field.replicable = false
        }
      }

      if (field.name == 'tenant_id') {
        field.items = state.lastSearchTenants

        if (group) {
          field.value = group.tenant_id
          field.readonly = true
        }
      }

      if (field.name == 'company_id') {

        if (group) {
          field.items = state.lastSearchCompanies
          field.value = group.company_id
          field.readonly = true
        }
      }

      if (field.name == 'active') {
        if (group) {
          field.value = group.active
        }
      }

      if (field.name == 'application_ids') {
        if (group) {
          field.value = group.application_ids
        }
      }

      if (field.name == 'application_instance_ids') {
        if (group) {
          field.value = group.application_instance_ids
        }
      }

      if (field.name == 'scope_global') {
        field.value = false
      }

      if (field.name == 'theme') {
        if (group) {
          field.value = group.theme_id
        }
      }
    })
  },

  [GroupMutations.SET_GROUPS](state, groups) {
    state.groups = [...groups]
  },

  [GroupMutations.SET_SELECTED_TENANT](state, tenant) {
    state.selectedTenant = tenant
  },

  [GroupMutations.SET_LAST_SEARCH_TENANTS](state, tenants) {
    state.lastSearchTenants = tenants
  },

  [GroupMutations.SET_LAST_SEARCH_COMPANIES](state, companies) {
    state.lastSearchCompanies = companies
  },

  [GroupMutations.SET_INACTIVE_FILTER_VAL](state, active) {
    state.inactiveFilterValue = active
  },

  [GroupMutations.SET_THEME_ITEMS](state, items) {
    state.fields.find(f => f.name === 'theme').items = items
  },
}