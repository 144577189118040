import AxiosNode from '@/services/api/node/axios_node'

export default {
  createMarkCategory: (data) => {
    return AxiosNode.post('mark/category', data)
  },

  getMarkCategory: (markCatId) => {
    return AxiosNode.get('mark/category/' + markCatId)
  },

  getMarkCategories: (options) => {
    return AxiosNode.get('mark/category', {params: options})
  },

  getMarkCategoriesFromTenant: (tenantId) => {
    return AxiosNode.get('mark/category/tenant/' + tenantId)
  },

  updateMarkCategory: (data, markCatId) => {
    return AxiosNode.put('mark/category/' + markCatId, data)
  },

  deleteMarkCategory: (markCatId) => {
    return AxiosNode.delete('mark/category/' + markCatId)
  },

  createSecurityGroup: (data) => {
    return AxiosNode.post('mark/securitygroup', data)
  },

  getSecurityGroup: (secGroupId) => {
    return AxiosNode.get('mark/securitygroup/' + secGroupId)
  },

  getSecurityGroups: (options) => {
    return AxiosNode.get('mark/securitygroup', {params: options})
  },

  getSecurityGroupsFromTenant: (tenantId) => {
    const params = {order: 'security_group_key', direction: 'ASC'}

    return AxiosNode.get('mark/securitygroup/tenant/' + tenantId, {params: params})
  },

  getSecurityMarksBySecurityGroup: (secGroupId) => {
    const params = {order: 'security_mark_key', direction: 'ASC', limit: 1000}

    return AxiosNode.get(`/mark/item/securitygroup/${secGroupId}`, {params: params})
  },

  getSecurityGroupsFromNode: (nodeId) => {
    const params = {order: 'security_group_key', direction: 'ASC'}

    return AxiosNode.get('mark/securitygroup/node/' + nodeId, {params: params})
  },

  updateSecurityGroup: (data, secGroupId) => {
    return AxiosNode.put('mark/securitygroup/' + secGroupId, data)
  },

  deleteSecurityGroup: (secGroupId) => {
    return AxiosNode.delete('mark/securitygroup/' + secGroupId)
  },

  createSecurityMark: (data) => {
    return AxiosNode.post('mark/item', data)
  },

  getSecurityMark: (secMarkId) => {
    return AxiosNode.get('mark/item/' + secMarkId)
  },

  getSecurityMarksFromSecGroup: (secGroupId) => {
    return AxiosNode.get('mark/item/securitygroup/' + secGroupId)
  },

  getSecurityMarksFromMarkCateg: (markCategId) => {
    return AxiosNode.get('mark/item/category/' + markCategId)
  },

  getSecurityMarksFromTenant: (tenantId) => {
    return AxiosNode.get('mark/item/tenant/' + tenantId)
  },

  updateSecurityMark: (data, secMarkId) => {
    return AxiosNode.put(`mark/item/${secMarkId}`, data)
  },

  deleteSecurityMark: (secMarkId) => {
    return AxiosNode.delete(`mark/item/${secMarkId}`)
  },
}
