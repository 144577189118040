import api from '@/services/api'
import TenantActions from './actions-types'
import TenantMutations from './mutations-types'

export default {
  [TenantActions.SAVE_TENANT]({ state }) {
    return new Promise((resolve, reject) => {
      if (state.selectedTenant) {
        let data = {}

        state.fields.forEach((item) => {
          let key = item.name

          if (key == 'title') {
            key = 'tenant_title'
          }

          if (key !== 'theme') {
            data[key] = item.value
          }
        })

        api.core.tenant
          .updateTenant(data, state.selectedTenant.tenant_id)
          .then((response) => {
            resolve(response.body)
          })
          .catch(() => reject())

      } else {
        let data = {}

        state.fields.forEach((item) => {
          data[item.name] = item.value
        })

        api.core.tenant
          .createTenant(data)
          .then((response) => {
            resolve(response.body)
          })
          .catch(() => reject())
      }
    })
  },

  [TenantActions.GET_ALL_TENANTS]({commit}, options) {
    return new Promise((resolve, reject) => {
      api.core.tenant
        .getAllTenants(options)
        .then((response) => {
          commit(TenantMutations.SET_TENANTS, response.data.items)
          resolve(response.data)
        })
        .catch(() => reject())
    })
  },

  [TenantActions.GET_TENANT]({}, id) {
    return new Promise((resolve, reject) => {
      api.core.tenant
        .getTenant(id)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => reject())
    })
  },

  [TenantActions.DELETE_TENANT]({ state }) {
    return new Promise((resolve, reject) => {
      api.core.tenant
        .deleteTenant(state.selectedTenant.tenant_id)
        .then(() => {
          resolve()
        })
        .catch(() => reject())
    })
  },

  [TenantActions.GET_ALL_FROM_CURRENT_USER]({}, options) {
    return new Promise((resolve, reject) => {
      api.core.tenant
        .getAllFromCurrentUser(options)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => reject())
    })
  },

  [TenantActions.LIST_TENANT_QUOTAS]({}, id) {
    return new Promise((resolve, reject) => {
      api.core.tenant
        .listTenantQuotas(id)
        .then((response) => { resolve(response.data) })
        .catch(() => reject())
    })
  },

  [TenantActions.SAVE_TENANT_QUOTA]({ state }) {
    return new Promise((resolve, reject) => {
      let data = {}
      let payload = {}

      state.quotaFields.forEach((item) => {
        data[item.name] = item.value
      })

      if (state.selectedQuota) {
        payload = {
          "balance_limit": data.balance_limit,
          "monthly_credit": data.monthly_credit,
          "type_of_billing": data.type_of_billing,
        }

        api.core.tenant
          .editQuota(payload, data.service, state.selectedTenant.tenant_id)
          .then(() => resolve())
          .catch(() => reject())
      } else {
        payload = {
          "tenant_id": state.selectedTenant.tenant_id,
          "service": data.service,
          "type_of_billing": data.type_of_billing,
        }

        api.core.tenant
          .createQuota(payload)
          .then(() => resolve())
          .catch(() => reject())
      }
    })
  },

  [TenantActions.ADD_CREDIT]({ state }, data) {
    return new Promise((resolve, reject) => {
      api.core.tenant
        .addCredit(data, state.selectedQuota.service, state.selectedTenant.tenant_id)
        .then(() => resolve())
        .catch(() => reject())

    })
  },

  [TenantActions.GET_SERVICE_TENANT_QUOTA]({ }, {service, tenantId}) {
    return new Promise((resolve, reject) => {
      api.core.tenant
        .getServiceTenantQuota(service, tenantId)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => reject(error))
    })
  },
}