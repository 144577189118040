import api from '@/services/api'
import UnomiConsoleActions from './actions-types'
import UnomiConsoleMutations from './mutations-types'

export default {
  [UnomiConsoleActions.SEND_COMMAND]({ commit }, command) {
    return new Promise((resolve, reject) => {
      api.cdp.unomi_console
        .sendCommand({command})
        .then((response) => {
          commit(UnomiConsoleMutations.ADD_OUTPUT_RESULT, {command, result: response.data})

          resolve(response.data)
        })
        .catch(() => reject())
    })
  },
}
