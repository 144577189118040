import RepositoryMutations from './mutations-types'

export default {
  [RepositoryMutations.SET_REPOSITORIES](state, repositories) {
    state.repositories = [...repositories]
  },

  [RepositoryMutations.SET_SELECTED_REPOSITORY](state, repository) {
    state.selectedRepository = repository

    state.fields.forEach((field) => {
      field.value = null

      if (repository) {
        field.value = repository[field.name]
      }

      if (field.name == 'title') {
        field.autofocus = true

        if (repository) {
          field.autofocus = false
        }
      }

      if (field.name == 'folder_name') {
        field.hide = false

        if (repository) {
          field.hide = true
        }
      }

      if (field.name == 'lib3d_node_id') {
        field.hide = false
      }

      if (field.name == 'tenant_id') {
        field.items = []
        field.hide  = false

        if (repository) {
          field.hide = true
        }
      }

      if (field.name == 'active') {
        if (repository) {
          field.hide = false

          return
        }

        field.value = true
        field.hide = true
      }
    })
  },

  [RepositoryMutations.SET_FIELD_FILE_SERVERS](state, fileServers) {
    state.fields.find(f => f.name == 'file_server_id').items = [...fileServers]
  },

  [RepositoryMutations.SET_FIELD_TENANTS](state, {tenants, clearValue}) {
    let field = state.fields.find(f => f.name == 'tenant_id')

    field.items = [...tenants]

    if (clearValue) {
      field.value = null
    }
  },

  [RepositoryMutations.SET_INACTIVE_FILTER_VAL](state, active) {
    state.inactiveFilterValue = active
  },
}
