import FileServerMutations from './mutations-types'

export default {
  [FileServerMutations.SET_FILE_SERVERS](state, fileServers) {
    state.fileServers = [...fileServers]
  },
  
  [FileServerMutations.SET_SELECTED_FILE_SERVER](state, fileServer) {
    state.selectedFileServer = fileServer

    state.fields.forEach((field) => {
      field.value = null

      if (fileServer) {
        field.value = fileServer[field.name]
      }

      if (field.name == 'label') {
        field.autofocus = true

        if (fileServer) {
          field.autofocus = false
        }
      }

      if (field.name == 'active') {
        if (fileServer) {
          field.hide  = false

          return
        }
        
        field.value = true
        field.hide = true
      }
    })
  },

  [FileServerMutations.SET_FIELD_TENANTS](state, tenants) {
    state.fields.find(f => f.name == 'tenant_ids').items = [...tenants]
  },

  [FileServerMutations.SET_INACTIVE_FILTER_VAL](state, active) {
    state.inactiveFilterValue = active
  },
}
