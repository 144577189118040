import api from '@/services/api'
import RenditionActions from './actions-types'

export default {
  [RenditionActions.GET_PREVIEW]({ }, nodeId) {
    return new Promise((resolve, reject) => {
      api.content.rendition
        .getPreview(nodeId)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => reject(error))
    })
  },

  [RenditionActions.REMOVE_RENDITION]({ }, nodeId) {
    return new Promise((resolve, reject) => {
      api.content.rendition
        .removeRendition(nodeId)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => reject(error))
    })
  },
}
