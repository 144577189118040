export default {
    SAVE_TENANT: 'saveTenant',
    GET_ALL_TENANTS: 'getAllTenants',
    GET_TENANT: 'getTenant',
    DELETE_TENANT: 'deleteTenant',
    GET_ALL_FROM_CURRENT_USER: 'getAllFromCurrentUser',
    LIST_TENANT_QUOTAS: 'listTenantQuotas',
    SAVE_TENANT_QUOTA: 'saveTenantQuota',
    ADD_CREDIT: 'addCredit',
    GET_SERVICE_TENANT_QUOTA: 'getServiceTenantQuota',
}