import unomi_config from './cdp/unomi_config'
import customer_property from './cdp/customer_property'
import profile_view from './cdp/profile_view'
import attribute_mapping from './cdp/attribute_mapping'
import unomi_console from './cdp/unomi_console'

export default {
  customer_property,
  profile_view,
  unomi_config,
  attribute_mapping,
  unomi_console,
}
