import i18n from '@/i18n.js'

export default {
  security_categories: [],

  tableHeaders: [
    {text: 'Key', value: 'mark_category_key'},
    {text: 'Tenant', value: 'tenant_id'},
    {text: 'Actions', value: 'actions', sortable: false}
  ],

  selectedSecurityCategory: {},

  fields: [
    {
      name: 'mark_category_key',
      type: 'text',
      label: 'Key',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      autofocus: true,
      required: true,
      hide: false,
      value: null
    },
    {
      name: 'tenant_id',
      type: 'select',
      label: 'Tenant',
      items: [],
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      itemText: 'title',
      itemValue: 'tenant_id',
      required: true,
      hide: false,
      chips: true,
      multiple: false
    }
  ]
}
