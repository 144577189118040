<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 24 24">
    <rect class="clas-w-2" x="2.95" y="3" width="18.11" height="18" rx="1.02" ry="1.02" />
    <path class="clas-w-1"
      d="M14.28,14.1l1.3-6.88h1.98l-2.14,9.67h-2l-1.57-6.47-1.57,6.47h-2L6.12,7.22h1.99l1.31,6.86,1.59-6.86h1.69l1.59,6.88Z" />
  </svg>
</template>

<style>
  .clas-w-1 {
    fill: #fff;
  }

  .clas-w-2 {
    fill: #1e65c4;
  }
</style>

<script>
  export default {
    props: {
      iconName: {
        type: String,
        default: 'office_word'
      },
      width: {
        type: [Number, String],
        default: 24
      },
      height: {
        type: [Number, String],
        default: 24
      },
    }
  }
</script>