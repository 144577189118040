import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import colors from 'vuetify/lib/util/colors'
import customIcons from './custom-icons'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#4cab3d',
        secondary: colors.grey.darken4,
        appBarBgColor: colors.shades.black,
        appBarTxtColor: colors.grey.lighten5,
        appBackground: colors.grey.lighten4,
        defaultIconColor: colors.grey.darken3,
        accent: '#82B1FF',
        error: colors.red.darken1,
        info: '#2196F3',
        success: colors.green.darken3,
        warning: '#FB8C00',
      },
    },
  },
  icons: {
    values: customIcons,
  },
});
