<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 24 24">
    <rect class="cls-a-2" x="2.4" y="3" width="19.2" height="18" rx="2.81" ry="2.81" />
    <g>
      <path class="cls-a-1"
        d="M7.36,14.04l-.64,1.97c-.03,.07-.07,.11-.15,.11h-1.54c-.09,0-.12-.05-.11-.13l2.66-7.66c.05-.15,.11-.31,.13-.81,0-.05,.04-.09,.08-.09h2.13c.07,0,.09,.01,.11,.08l3.02,8.5c.03,.07,.01,.12-.07,.12h-1.73c-.08,0-.12-.03-.15-.09l-.68-1.98h-3.07Zm2.58-1.66c-.27-.88-.8-2.48-1.05-3.43h-.01c-.21,.89-.69,2.35-1.04,3.43h2.1Z" />
      <path class="cls-a-1"
        d="M13.88,11.05c0-.45-.03-1.14-.05-1.43-.01-.07,.01-.11,.09-.11h1.39c.11,0,.15,.04,.17,.11,.05,.12,.08,.25,.11,.44,.56-.45,1.18-.68,1.89-.68,1.02,0,2.23,.53,2.23,2.67v3.95c0,.08-.03,.12-.12,.12h-1.59c-.09,0-.13-.04-.13-.12v-3.79c0-.8-.35-1.22-1.04-1.22-.45,0-.82,.16-1.1,.41v4.61c0,.07-.04,.11-.12,.11h-1.61c-.09,0-.12-.04-.12-.12v-4.94Z" />
    </g>
  </svg>
</template>

<style>
  .cls-a-1 {
    fill: #99f;
  }

  .cls-a-2 {
    fill: #00005b;
  }
</style>

<script>
  export default {
    props: {
      iconName: {
        type: String,
        default: 'adobe_animate'
      },
      width: {
        type: [Number, String],
        default: 24
      },
      height: {
        type: [Number, String],
        default: 24
      },
    }
  }
</script>