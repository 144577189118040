import action from './actions/action'
import upload from './actions/upload'
import folder from './actions/folder'
import file from './actions/file'
import entry from './actions/entry'
import gdocs from './actions/gdocs'
import synthetic from './synthetic'
import self_service_template from './self_service_template'

export default {
  action,
  upload,
  folder,
  file,
  entry,
  gdocs,
  synthetic,
  self_service_template
}
