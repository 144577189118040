import AuthMutations from './mutations-types'
import UserRoles from '@/store/core/user/user-roles'

export default {
  [AuthMutations.SET_AUTHENTICATED](state, payload) {
    state.authenticated = payload
  },

  [AuthMutations.SET_TOKEN](state, token) {
    state.token = token

    if (token) {
        localStorage.setItem('token', token)
        return
    }

    localStorage.removeItem('token')
  },

  [AuthMutations.SET_USER_DATA](state, userData) {
    state.userData = userData
  },

  [AuthMutations.SET_USER_ROLES](state) {
    state.userRoles = []
    
    if (state.userData.master) {
      state.userRoles = [...state.userRoles, UserRoles.USER_MASTER]
    } else {
      state.userRoles = [...state.userRoles, UserRoles.USER_REGULAR]
    }

    if (state.userData.admins.length > 0) {
      state.userRoles = [...state.userRoles, UserRoles.USER_ADMIN]
    }

    if (state.userData.groups.length > 0) {
      for (let i = 0; i < state.userData.groups.length; i++) {
        if (state.userData.groups[i].manager == true) {
          state.userRoles = [...state.userRoles, UserRoles.USER_MANAGER]
        }
      }
    }
  },

  [AuthMutations.SET_LAST_PATH](state, path) {
    state.lastPath = path
  }
}
