import application_instance from './core/application_instance'
import auth from './core/auth'
import theme from './core/theme'
import tenant from './core/tenant'
import company from './core/company'
import user from './core/user'
import group from './core/group'
import notification from './core/notification'
import key_value_store from './core/key_value_store'

export default {
  application_instance,
  auth,
  theme,
  tenant,
  company,
  user,
  group,
  notification,
  key_value_store,
}
