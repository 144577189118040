import api from '@/services/api'
import ActionActions from './actions-types'

export default {
  [ActionActions.GET_ALL_ACTIONS]({}, options) {
    return new Promise((resolve, reject) => {
      api.node.action
        .getActions(options)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => reject())
    })
  }
}