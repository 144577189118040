import api from '@/services/api'
import EnablerActions from './actions-types'

export default {
  [EnablerActions.GET_ALL_ENABLERS]({}, options) {
    return new Promise((resolve, reject) => {
      api.node.enabler
        .getEnablers(options)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => reject())
    })
  },

  [EnablerActions.SAVE_ENABLER]({ state }) {
    return new Promise((resolve, reject) => {
      let data = {}
      state.fields.forEach((item) => {
        data[item.name] = item.value
      })

      if (state.selectedEnabler) {
        api.node.enabler
          .updateEnabler(data, state.selectedEnabler.enabler_id)
          .then((response) => {
            resolve(response.body)
          })
          .catch((error) => {
            reject(error.response.data)
          })
      } else {
        api.node.enabler
          .createEnabler(data)
          .then((response) => {
            resolve(response.body)
          })
          .catch((error) => {
            reject(error.response.data)
          })
      }
    })
  },

  [EnablerActions.UPDATE_ENABLER]({ state }, data) {
    data.aspect_key = state.selectedEnabler.aspect_key

    return new Promise((resolve, reject) => {
      api.node.enabler
        .updateEnabler(data, state.selectedEnabler.enabler_id)
        .then((response) => {
          resolve(response.body)
        })
        .catch((error) => {
          reject(error.response.data)
        })
    })
  },

  [EnablerActions.GET_ENABLERS_BY_NODE]({}, params) {
    return new Promise((resolve, reject) => {
      api.node.enabler
        .getEnablersByNode(params.nodeId, params.options)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => reject())
    })
  },

  [EnablerActions.ADD_ENABLER_NODE_RELATIONSHIP]({}, params) {
    return new Promise((resolve, reject) => {
      api.node.aspect
        .addAspectNodeRelationship(params.enablerId, params.nodeId, params.data)
        .then(() => {
          resolve()
        })
        .catch(() => reject())
    })
  },

  [EnablerActions.REMOVE_ENABLER_NODE_RELATIONSHIP]({}, params) {
    return new Promise((resolve, reject) => {
      api.node.aspect
        .removeAspectNodeRelationship(params.enablerId, params.nodeId)
        .then(() => {
          resolve()
        })
        .catch(() => reject())
    })
  }
}
