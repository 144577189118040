import ProfileViewMutations from './mutations-types'

export default {

  [ProfileViewMutations.SET_PROFILE_VIEWS](state, profileViews) {
    state.profileViews = [...profileViews]
  },
  
  [ProfileViewMutations.SET_SELECTED_PROFILE_VIEW](state, profileView) {
    state.selectedProfileView = profileView

    state.fields.forEach((field) => {
      field.value = null

      if (profileView) {
        field.value = profileView[field.name]
      }

      if (field.name == 'profile_view_title') {
        field.autofocus = true

        if (profileView) {
          field.autofocus = false
        }
      }

      if (field.name == 'profile_view_key') {
        field.disabled = false
        field.replicable = true
        
        if (profileView) {
          field.disabled = true
          field.replicable = false
        }
      }

      if (field.name == 'customer_properties') {
        field.items = []

        if (profileView) {
          let customerPropertiesIds = []

          profileView.customer_properties.forEach(item => customerPropertiesIds.push(item.customer_property_id))

          field.value = customerPropertiesIds
        }
      }

      if (field.name == 'user_groups') {
        field.items = []

        if (profileView) {
          let userGroupsIds = []

          profileView.user_groups.forEach(item => userGroupsIds.push(item.user_group_id))

          field.value = userGroupsIds
        }
      }

      if (field.name == 'active') {
        if (profileView) {
          field.hide  = false

          return
        }
        
        field.value = true
        field.hide = true
      }
    })

    state.exportFields.forEach((field) => {
      field.value = null

      if (profileView) {
        field.value = profileView[field.name]
      }
    })
  },

  [ProfileViewMutations.SET_FIELD_TENANTS](state, tenants) {
    state.fields.find(f => f.name == 'tenant_id').items = tenants
  },

  [ProfileViewMutations.SET_FIELD_CUSTOMER_PROPERTIES](state, {customerProperties, clearValue}) {
    let field = state.fields.find(f => f.name == 'customer_properties')

    field.items = [...customerProperties]

    if (clearValue) {
      field.value = null
    }
  },

  [ProfileViewMutations.SET_FIELD_GROUPS](state, {groups, clearValue}) {
    let field = state.fields.find(f => f.name == 'user_groups')

    field.items = [...groups]

    if (clearValue) {
      field.value = null
    }
  },

  [ProfileViewMutations.SET_INACTIVE_FILTER_VAL](state, active) {
    state.inactiveFilterValue = active
  }
}