import api from '@/services/api'
import ApplicationInstanceActions from './actions-types'
import ApplicationInstanceMutations from './mutations-types'

export default {
  [ApplicationInstanceActions.GET_ALL]({ commit }, options = {}) {
    return new Promise((resolve, reject) => {
      api.core.application_instance
          .getAll(options)
          .then((response) => {
            commit(ApplicationInstanceMutations.SET_APPLICATION_INSTANCES, response.data)
            resolve(response.data)
          })
          .catch(() => reject())
    })
  },

  [ApplicationInstanceActions.SAVE_APPLICATION_INSTANCE]({ state }) {
    return new Promise((resolve, reject) => {
      let data = {}
      
      state.fields.forEach((item) => {
        data[item.name] = item.value
      })

      if (state.selectedApplicationInstance) {
        api.core.application_instance
          .updateApplicationInstance(data, state.selectedApplicationInstance.application_instance_id)
          .then((response) => {
            resolve(response.body)
          })
          .catch(() => reject())
      } else {
        api.core.application_instance
          .createApplicationInstance(data)
          .then((response) => {
            resolve(response.body)
          })
          .catch(() => reject())
      }
    })
  },

  [ApplicationInstanceActions.DELETE_APPLICATION_INSTANCE]({ state }) {
    return new Promise((resolve, reject) => {
      api.core.application_instance
        .deleteApplicationInstance(state.selectedApplicationInstance.application_instance_id)
        .then(() => {
          resolve()
        })
        .catch(() => reject())
    })
  },

  [ApplicationInstanceActions.GET_USER_APP_INSTANCES]({ }) {
    return new Promise((resolve, reject) => {
      api.core.application_instance
        .getUserAppInstances()
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => reject())
    })
  },
}