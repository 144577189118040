export default {
  customerPropertyTitleById: (state) => (id) => {
    let item = state
      .customerProperties
      .find(customerProperty => customerProperty.customer_property_id == id)

    if (!item) {
      return ''
    }

    return item.customer_property_title
  }
}