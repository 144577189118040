import UnomiConfigMutations from './mutations-types'

export default {

  [UnomiConfigMutations.SET_UNOMI_CONFIGS](state, unomiConfigs) {
    state.unomiConfigs = [...unomiConfigs]
  },
  
  [UnomiConfigMutations.SET_SELECTED_UNOMI_CONFIG](state, unomiConfig) {
    state.selectedUnomiConfig = unomiConfig

    state.fields.forEach((field) => {
      field.value = null

      if (unomiConfig) {
        field.value = unomiConfig[field.name]
      }

      if (unomiConfig && field.name == 'customer_properties') {
        let customerPropertiesIds = []

        unomiConfig.customer_properties.forEach(item => customerPropertiesIds.push(item.customer_property_id))

        field.value = customerPropertiesIds
      }
    })
  },

  [UnomiConfigMutations.SET_FIELD_TENANTS](state, tenants) {
    state.fields.find(f => f.name == 'tenant_id').items = tenants
  },

  [UnomiConfigMutations.SET_FIELD_CUSTOMER_PROPERTIES](state, customerProperties) {
    state.fields.find(f => f.name == 'customer_properties').items = customerProperties
  }
}