<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 24 24">
    <rect class="clas-d-2" x="2.4" y="3" width="19.2" height="18" rx="2.81" ry="2.81" />
    <g>
      <path class="clas-d-1"
        d="M4.19,7.67c0-.06,.01-.09,.09-.1,.38-.01,1.35-.04,2.48-.04,3.04,0,4.52,1.7,4.52,4.05,0,3.04-2.34,4.32-4.5,4.32-.84,0-2.11,0-2.52-.03-.05,0-.08-.04-.08-.13V7.67Zm1.77,6.68c.25,.01,.46,.03,.75,.03,1.67,0,2.76-1,2.76-2.77s-1.09-2.54-2.72-2.54c-.31,0-.51,0-.79,.01v5.27Z" />
      <path class="clas-d-1"
        d="M17.19,15.88c-.1,0-.13-.04-.15-.11-.63-2.59-.84-3.76-.97-4.38h-.01c-.32,1.4-.57,2.45-1.11,4.38-.03,.09-.06,.11-.14,.11h-1.59c-.13,0-.15-.03-.18-.11l-1.65-6.09c-.03-.06,.01-.11,.1-.11h1.61c.08,0,.1,.03,.11,.09,.67,2.75,.85,3.97,.94,4.5h.03c.1-.46,.3-1.65,1.04-4.47,.01-.08,.03-.11,.11-.11h1.68c.06,0,.09,.03,.1,.09,.67,2.81,.85,4.04,.94,4.55h.03c.18-.83,.37-2.2,.98-4.55,.01-.05,.04-.09,.11-.09h1.49c.05,0,.1,.04,.08,.1l-1.82,6.1c-.03,.09-.04,.11-.15,.11h-1.59Z" />
    </g>
  </svg>
</template>

<style>
  .clas-d-1 {
    fill: #ff61f6;
  }

  .clas-d-2 {
    fill: #470137;
  }
</style>

<script>
  export default {
    props: {
      iconName: {
        type: String,
        default: 'adobe_dreamweaver'
      },
      width: {
        type: [Number, String],
        default: 24
      },
      height: {
        type: [Number, String],
        default: 24
      },
    }
  }
</script>