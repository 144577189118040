import SecurityCategoryMutations from './mutations-types'

export default {
  [SecurityCategoryMutations.SET_SECURITYCATEGORIES](state, security_categories) {
    state.security_categories = [...security_categories]
  },

  [SecurityCategoryMutations.SET_SELECTED_SECURITYCATEGORY](state, security_category) {
    state.selectedSecurityCategory = security_category

    state.fields.forEach((field) => {
      field.value = null

      if (security_category) {
        field.value = security_category[field.name]
      }
    })
  },

  [SecurityCategoryMutations.SET_FIELD_TENANTS](state, tenants) {
    state.fields.find(f => f.name == 'tenant_id').items = tenants
  },
}