import AxiosCore from '@/services/api/core/axios_core'

export default {
  login: (params) => {
    return AxiosCore.post('auth/login', params)
  },

  renewToken: (params) => {
    return AxiosCore.post('auth/renew_token', params)
  },

  resetPasswd: (params) => {
    return AxiosCore.post('auth/reset_password', params)
  }
}
