<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24">
    <defs>
      <linearGradient id="linear-gradient" x1="12" y1="21.5" x2="12" y2="2.5" gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="#1e64f0" />
        <stop offset="1" stop-color="#1ad3fd" />
      </linearGradient>
    </defs>
    <rect class="cls-key-2" x="2.5" y="2.5" width="19" height="19" rx="3.19" ry="3.19" />
    <path class="cls-key-1"
      d="M17.27,11.42H6.73l.17-4.18c.01-.32,.28-.57,.6-.57h.37v-.78c0-.36,.3-.65,.67-.65h1.32c.07,0,.13-.04,.16-.1,.08-.19,.27-.32,.49-.32h1.1c.25,0,.45,.2,.45,.45v.16c0,.25-.2,.45-.45,.45h-1.1c-.19,0-.36-.1-.45-.25-.03-.05-.1-.08-.16-.08h-1.35c-.2,0-.36,.15-.36,.34v.78h8.32c.32,0,.58,.25,.6,.57l.17,4.18Zm-10.9,.38c0,.28,.23,.51,.51,.51h10.23c.28,0,.51-.23,.51-.51H6.37Zm8,5.68h-1.68c-.25,0-.44-.2-.44-.44v-4.4h-.52v4.4c0,.25-.2,.44-.44,.44h-1.68c-.14,0-.26,.12-.26,.26s.12,.26,.26,.26h4.77c.14,0,.26-.12,.26-.26s-.12-.26-.26-.26Z" />
  </svg>
</template>
  
<style>
  .cls-key-1 {
      fill: #fff;
  }

  .cls-key-2 {
      fill: url(#linear-gradient);
  }
</style>
  
<script>
  export default {
    props: {
      iconName: {
        type: String,
        default: 'keynote'
      },
      width: {
        type: [Number, String],
        default: 24
      },
      height: {
        type: [Number, String],
        default: 24
      },
    }
  }
</script>