import action from './action'
import actionset from './actionset'
import enabler from './enabler'
import security_group from './security_group'
import security_category from './security_category'
import security_mark from './security_mark'
import classification from './classification'
import clearance from './clearance'
import filter from './filter'
import field from './field'

export default {
  namespaced: true,
  modules: {
    action,
    actionset,
    enabler,
    security_group,
    security_category,
    security_mark,
    filter,
    field,
    classification,
    clearance
  }
}
