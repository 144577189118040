import api from '@/services/api'
import CompanyActions from './actions-types'

export default {
  [CompanyActions.SAVE_COMPANY]({ state }) {
    return new Promise((resolve, reject) => {
      let data = {}
      state.fields.forEach((item) => {
        data[item.name] = item.value
      })

      if (state.selectedCompany) {
        api.core.company
          .updateCompany(data, state.selectedCompany.company_id)
          .then((response) => {
            resolve(response.body)
          })
          .catch((error) => {
            reject(error.response.data)
          })
      } else {
        api.core.company
          .createCompany(data)
          .then((response) => {
            resolve(response.body)
          })
          .catch((error) => {
            reject(error.response.data)
          })
      }
    })
  },

  [CompanyActions.GET_ALL_COMPANIES]({}, options) {
    return new Promise((resolve, reject) => {
      api.core.company
        .getAllCompanies(options)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => reject())
    })
  },

  [CompanyActions.GET_COMPANIES_BY_USER_GROUP]({}, params) {
    return new Promise((resolve, reject) => {
      api.core.company
        .getCompaniesByUserGroup(params.userGroupId, params)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => reject())
    })
  },

  [CompanyActions.DELETE_COMPANY]({ state }) {
    return new Promise((resolve, reject) => {
      api.core.company
        .deleteCompany(state.selectedCompany.company_id)
        .then(() => {
          resolve()
        })
        .catch(() => reject())
    })
  },

  [CompanyActions.ADD_COMPANY_GROUP_RELATIONSHIP_BY_KEY]({}, params) {
    return new Promise((resolve, reject) => {
      api.core.company
        .addCompanyUserGroupRelationshipByKey(params.userGroupId, params)
        .then(() => {
          resolve()
        })
        .catch(() => reject())
    })
  },

  [CompanyActions.DELETE_COMPANY_GROUP_RELATIONSHIP]({}, params) {
    return new Promise((resolve, reject) => {
      api.core.company
        .deleteCompanyUserGroupRelationship(params.companyId, params.userGroupId)
        .then(() => {
          resolve()
        })
        .catch(() => reject())
    })
  },

  [CompanyActions.GET_COMPANIES_BY_TENANT]({}, tenantId) {
    return new Promise((resolve, reject) => {
      api.core.company
        .getCompaniesByTenant(tenantId)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => reject())
    })
  }
}