import core from './api/core'
import cdp from './api/cdp'
import content from './api/content'
import node from './api/node'

export default {
  core,
  cdp,
  content,
  node
}
