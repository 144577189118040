import AxiosCore from '@/services/api/core/axios_core'

export default {
  createGroup: (data) => {
    return AxiosCore.post('usergroup', data)
  },

  updateGroup: (data, id) => {
    return AxiosCore.put(`usergroup/${id}`, data)
  },

  getAllGroups: (params) => {
    return AxiosCore.get('usergroup', {params})
  },

  getGroupsByCompany: (companyId, params) => {
    return AxiosCore.get(`usergroup/company/${companyId}`, {params})
  },

  getUserGroup: (groupId) => {
    return AxiosCore.get(`usergroup/${groupId}`)
  },

  getUsersFromGroup: (groupId, params) => {
    return AxiosCore.get(`usergroup/${groupId}/users`, {params})
  },

  deleteUserFromGroup: (groupId, userId) => {
    return AxiosCore.delete(`usergroup/${groupId}/users/${userId}`)
  }
}
