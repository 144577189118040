import api from '@/services/api'
import ContentActions from './actions-types'
import store from '@/store'
import BackgroundActions from '@/store/background_actions/actions-types.js'
import BackgroundMutations from '@/store/background_actions/mutations-types.js'
import AppActions from '@/store/app/actions-types.js'
import EntryActions from '@/store/content/entry/actions-types'
import EntryMutations from '@/store/content/entry/mutations-types'

export default {
  [ContentActions.GET_NODE_ACTIONS]({ }, options = {}) {
    return new Promise((resolve, reject) => {
      api.content.actions.action
        .getNodeActions(options)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => reject())
    })
  },

  [ContentActions.UPLOAD_FILE]({ commit, dispatch }, {parentId, file}) {
    dispatch(`background_actions/${[BackgroundActions.CREATE_BACKGROUND_ACTION]}`, {title: file.name, icon: 'mdi-file-upload-outline'}, { root:true })
      .then((bgAction) => {
        const bgActionId = bgAction.bgActionId

        api.content.actions.upload.uploadFile({parentId, file}, bgAction.cancelSource, event => {
          const progress = Math.round((100 * event.loaded) / event.total)

          commit(`background_actions/${[BackgroundMutations.UPDATE_BACKGROUND_ACTION_PROGRESS]}`, {bgActionId, progress}, { root:true })
        })
        .then(() => {
          dispatch(`content/entry/${[EntryActions.LIST_SUBFOLDER]}`, {parentId}, { root:true })
        })
        .catch(() => {
          commit(`background_actions/${[BackgroundMutations.UPDATE_BACKGROUND_ACTION_STATUS]}`, {bgActionId, status: 'error'}, { root:true })
        })
      })
  },

  [ContentActions.DOWNLOAD_FILE]({}, {nodeId, control}) {
      if (!this.downloading) {
        this.downloading = true;
        api.content.actions.file.download(nodeId)
        .then((response) => {
          const link = document.createElement('a')
          link.target = '_blank';
          const downloadLink = new URL(response.data.link)
          link.href = downloadLink.toString()
          link.click()
        })
        .catch((error) => {
          this.downloading = false
          store.dispatch('app/' + AppActions.OPEN_APP_ERROR_MESSAGE, error.message)
        })
        .finally(() => {
          control.downloading = false
          this.downloading = false
        })
      }
  },

  [ContentActions.CUSTOM_ACTION]({ dispatch }, {nodeId, pipelineId, parentId, routine, name, parameters}) {
    return new Promise((resolve, reject) => {
      api.content.actions.entry
        .customAction(nodeId, pipelineId, parameters)
        .then((response) => {
          resolve(response.data)

          const bgAction = {
            bgActionId: Math.random().toString(36).substr(2, 9),
            icon: 'mdi-motion-play-outline',
            title: `${routine} ${name}`,
            createdAt: new Date(),
            progress: 0,
            status: 'in_progress',
            cancelSource: null,
            nodeId: nodeId,
            pipelineId: pipelineId
          }

          const actionName = `background_actions/${[BackgroundActions.CREATE_BACKGROUND_ACTION_AND_CHECK_STATUS]}`

          dispatch(actionName, bgAction, { root:true })
            .then(() => {
              dispatch(`content/entry/${[EntryActions.LIST_SUBFOLDER]}`, {parentId}, { root:true })
            })
        })
        .catch(() => {
          reject()
        })
    })
  },

  [ContentActions.CREATE_FOLDER]({ dispatch }, params) {
    return new Promise((resolve, reject) => {
      api.content.actions.folder
        .createFolder(params)
        .then((response) => {
          dispatch(`content/entry/${[EntryActions.LIST_SUBFOLDER]}`, {parentId: params.folder_parent_id}, { root:true })

          resolve(response.data)
        })
        .catch(() => reject())
    })
  },

  [ContentActions.RENAME]({ commit }, {nodeId, params}) {
    return new Promise((resolve, reject) => {
      api.content.actions.entry
        .renameEntry(nodeId, params)
        .then((response) => {
          commit(`content/entry/${[EntryMutations.UPDATE_ENTRY]}`, {nodeId, params}, { root:true })

          resolve(response.data)
        })
        .catch(() => reject())
    })
  },

  [ContentActions.REMOVE]({ commit, dispatch }, {nodeId, params}) {
    return new Promise((resolve, reject) => {
      dispatch(`background_actions/${[BackgroundActions.CREATE_BACKGROUND_ACTION]}`, {title: params.name, icon: 'mdi-delete-outline'}, { root:true })
        .then((bgAction) => {
          const bgActionId = bgAction.bgActionId

          api.content.actions.entry.removeEntry(nodeId, bgAction.cancelSource, {})
          .then((response) => {
            commit(`content/entry/${[EntryMutations.REMOVE_ENTRY]}`, nodeId, { root:true })
            commit(`background_actions/${[BackgroundMutations.UPDATE_BACKGROUND_ACTION_STATUS]}`, {bgActionId, status: 'success'}, { root:true })

            resolve(response.data)
          })
          .catch((error) => {
            commit(`background_actions/${[BackgroundMutations.UPDATE_BACKGROUND_ACTION_STATUS]}`, {bgActionId, status: 'error'}, { root:true })

            reject(error)
          })
        })
    })
  },

  [ContentActions.SYNC]({ }, nodeId) {
    return new Promise((resolve, reject) => {
      api.content.actions.entry
        .syncEntry(nodeId)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => reject())
    })
  },

  [ContentActions.MOVE]({ commit, dispatch }, {nodeId, params}) {
    return new Promise((resolve, reject) => {
      dispatch(`background_actions/${[BackgroundActions.CREATE_BACKGROUND_ACTION]}`, {title: params.name, icon: 'mdi-folder-move-outline'}, { root:true })
        .then((bgAction) => {
          const bgActionId = bgAction.bgActionId

          api.content.actions.entry.moveEntry(nodeId, params, bgAction.cancelSource, {})
          .then((response) => {
            commit(`content/entry/${[EntryMutations.REMOVE_ENTRY]}`, nodeId, { root:true })
            commit(`background_actions/${[BackgroundMutations.UPDATE_BACKGROUND_ACTION_STATUS]}`, {bgActionId, status: 'success'}, { root:true })

            resolve(response.data)
          })
          .catch((error) => {
            commit(`background_actions/${[BackgroundMutations.UPDATE_BACKGROUND_ACTION_STATUS]}`, {bgActionId, status: 'error'}, { root:true })

            reject(error)
          })
        })
    })
  },

  [ContentActions.MOVE_ENTRIES]({ commit, dispatch }, params) {
    return new Promise((resolve, reject) => {
      dispatch(`background_actions/${[BackgroundActions.CREATE_BACKGROUND_ACTION]}`, {title: params.name, icon: 'mdi-folder-move-outline'}, { root:true })
        .then((bgAction) => {
          const bgActionId = bgAction.bgActionId

          api.content.actions.entry.moveEntries(params, bgAction.cancelSource, {})
          .then(() => {
            params.entries.forEach((nodeId) => {
              commit(`content/entry/${[EntryMutations.REMOVE_ENTRY]}`, nodeId, { root:true })
            })
            commit(`background_actions/${[BackgroundMutations.UPDATE_BACKGROUND_ACTION_STATUS]}`, {bgActionId, status: 'success'}, { root:true })

            resolve()
          })
          .catch((error) => {
            commit(`background_actions/${[BackgroundMutations.UPDATE_BACKGROUND_ACTION_STATUS]}`, {bgActionId, status: 'error'}, { root:true })

            reject(error)
          })
        })
    })
  },

  [ContentActions.COPY]({ commit, dispatch }, {nodeId, params}) {
    return new Promise((resolve, reject) => {
      dispatch(`background_actions/${[BackgroundActions.CREATE_BACKGROUND_ACTION]}`, {title: params.name, icon: 'mdi-content-copy'}, { root:true })
        .then((bgAction) => {
          const bgActionId = bgAction.bgActionId

          api.content.actions.entry.copyEntry(nodeId, params, bgAction.cancelSource, {})
          .then((response) => {
            commit(`background_actions/${[BackgroundMutations.UPDATE_BACKGROUND_ACTION_STATUS]}`, {bgActionId, status: 'success'}, { root:true })

            resolve(response.data)
          })
          .catch((error) => {
            commit(`background_actions/${[BackgroundMutations.UPDATE_BACKGROUND_ACTION_STATUS]}`, {bgActionId, status: 'error'}, { root:true })

            reject(error)
          })
        })
    })
  },

  [ContentActions.CREATE_GDOC]({}, params) {
    return new Promise((resolve, reject) => {
      api.content.actions.gdocs
        .createGDoc(params)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => reject(error))
    })
  },

  [ContentActions.EDIT_GDOC]({}, nodeId) {
    return new Promise((resolve, reject) => {
      api.content.actions.gdocs
        .editGDoc(nodeId)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => reject(error))
    })
  },

  [ContentActions.FINISH_EDIT_GDOC]({}, {nodeId, params}) {
    return new Promise((resolve, reject) => {
      api.content.actions.gdocs
        .finishEditingGDoc(nodeId, params)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => reject(error))
    })
  },

  [ContentActions.CONVERT_TO_GDOC]({}, nodeId) {
    return new Promise((resolve, reject) => {
      api.content.actions.gdocs
        .convertToGDoc(nodeId)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => reject(error))
    })
  },

  [ContentActions.GENERATE_DOWNLOAD_LINK]({}, {nodeId, params}) {
    return new Promise((resolve, reject) => {
      api.content.actions.action
        .generateDownloadLink(nodeId, params)
        .then((response) => {
          resolve(response.data.download_link)
        })
        .catch(() => reject())
    })
  }
}
