import AttributeMappingMutations from './mutations-types'

export default {

  [AttributeMappingMutations.SET_ATTRIBUTE_MAPPINGS](state, attributeMappings) {
    state.attributeMappings = [...attributeMappings]
  },
  
  [AttributeMappingMutations.SET_SELECTED_ATTRIBUTE_MAPPING](state, attributeMapping) {
    state.selectedAttributeMapping = attributeMapping

    state.fields.forEach((field) => {
      field.value = null

      if (attributeMapping) {
        field.value = attributeMapping[field.name]
      }

      if (field.name == 'customer_property_attribute') {
        field.autofocus = true

        if (attributeMapping) {
          field.autofocus = false
        }
      }

      if (field.name == 'customer_properties') {
        field.items = []

        if (attributeMapping) {
          let customerPropertiesIds = []

          attributeMapping.customer_properties.forEach(item => customerPropertiesIds.push(item.customer_property_id))

          field.value = customerPropertiesIds
        }
      }
    })
  },

  [AttributeMappingMutations.SET_FIELD_TENANTS](state, tenants) {
    state.fields.find(f => f.name == 'tenant_id').items = [...tenants]
  },

  [AttributeMappingMutations.SET_FIELD_CUSTOMER_PROPERTIES](state, {customerProperties, clearValue}) {
    let field = state.fields.find(f => f.name == 'customer_properties')

    field.items = [...customerProperties]

    if (clearValue) {
      field.value = null
    }
  },
}