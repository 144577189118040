import UserRoles from '@/store/core/user/user-roles'

export default [
  {
    path: '/cdp/:appId/profile_view_export',
    name: 'ProfileViewExport',
    component: () => import('../views/cdp/profile_view_export/ProfileViewExport.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false,
      access_role: [
        UserRoles.USER_ADMIN,
        UserRoles.USER_MANAGER,
        UserRoles.USER_REGULAR
      ]
    }
  },
  {
    path: '/cdp/:appId/settings',
    name: 'CdpSettings',
    component: () => import('../views/cdp/settings/CdpSettings.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false
    }
  },
  {
    path: '/cdp/:appId/settings/customer_property',
    name: 'CustomerProperty',
    component: () => import('../views/cdp/customer_property/CustomerProperty.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false,
      access_role: [
        UserRoles.USER_ADMIN,
        UserRoles.USER_MANAGER,
        UserRoles.USER_REGULAR
      ]
    }
  },
  {
    path: '/cdp/:appId/settings/unomi_config',
    name: 'UnomiConfig',
    component: () => import('../views/cdp/unomi_config/UnomiConfig.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false,
      access_role: [
        UserRoles.USER_ADMIN,
        UserRoles.USER_MANAGER,
        UserRoles.USER_REGULAR
      ]
    }
  },
  {
    path: '/cdp/:appId/settings/unomi_console',
    name: 'UnomiConsole',
    component: () => import('../views/cdp/unomi_console/UnomiConsole.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false,
      access_role: [
        UserRoles.USER_ADMIN,
      ]
    }
  },
  {
    path: '/cdp/:appId/settings/profile_view',
    name: 'ProfileView',
    component: () => import('../views/cdp/profile_view/ProfileView.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false,
      access_role: [
        UserRoles.USER_ADMIN,
        UserRoles.USER_MANAGER,
        UserRoles.USER_REGULAR
      ]
    }
  },
  {
    path: '/cdp/:appId/settings/attribute_mapping',
    name: 'AttributeMapping',
    component: () => import('../views/cdp/attribute_mapping/AttributeMapping.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false,
      access_role: [
        UserRoles.USER_ADMIN,
        UserRoles.USER_MANAGER,
        UserRoles.USER_REGULAR
      ]
    }
  },
]
