import api from '@/services/api'
import SyntheticActions from './actions-types'

import BackgroundMutations from '@/store/background_actions/mutations-types.js'
import SyntheticMutations from '@/store/content/synthetic/mutations-types.js'

var poll = (taskOffset, taskTopic, bgActionId, commit) => {
  return new Promise((resolve, reject) => {
    let checkStatusPayload = `${taskTopic}_current`
    let isQueued = true

    const myInterval = setInterval(function() {
      api.core.key_value_store
        .getKeyValue(checkStatusPayload)
        .then((response) => {
          const data = JSON.parse(response.data?.value) || {}

          const currentOffset = data?.offset || 0
          let status = data?.status || "in_progress"
          
          let title = `In Queue: ${taskOffset - currentOffset}`
  
          if (currentOffset >= taskOffset) {
            isQueued = false

            checkStatusPayload = `${taskTopic}_${taskOffset}_status`
          }

          if (!isQueued) {
            title = "Processing"

            if (status !== "in_progress") {
              clearInterval(myInterval)

              title = "Completed"

              commit(`background_actions/${[BackgroundMutations.UPDATE_BACKGROUND_ACTION_TITLE]}`, {bgActionId, title}, { root:true })
              commit(`background_actions/${[BackgroundMutations.UPDATE_BACKGROUND_ACTION_STATUS]}`, {bgActionId, status}, { root:true })

              resolve()
            }
          }

          commit(`content/synthetic/${[SyntheticMutations.SET_CURRENT_GENERATION_MESSAGE]}`, title, { root:true })
          commit(`background_actions/${[BackgroundMutations.UPDATE_BACKGROUND_ACTION_TITLE]}`, {bgActionId, title}, { root:true })
        })
        .catch(() => {
          clearInterval(myInterval)

          commit(`background_actions/${[BackgroundMutations.UPDATE_BACKGROUND_ACTION_STATUS]}`, {bgActionId, status: 'error'}, { root:true })

          reject()
        })
      }, 2000)
  })
}

export default {

  [SyntheticActions.CREATE_SYNTHETIC_MEDIA]({}, {nodeId, name}) {
    return new Promise((resolve, reject) => {
      api.content.synthetic
        .create(nodeId, name)
        .then((response) => {
          resolve(response.data.id)
        })
        .catch((error) => reject(error))
    })
  },

  [SyntheticActions.GET_SYNTHETIC_MEDIA]({}, nodeId) {
    return new Promise((resolve, reject) => {
      api.content.synthetic
        .get(nodeId)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => reject(error))
    })
  },

  [SyntheticActions.SAVE_SYNTHETIC_MEDIA]({}, {nodeId, params}) {
    return new Promise((resolve, reject) => {
      api.content.actions.synthetic
        .save(nodeId, params)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => reject(error))
    })
  },

  [SyntheticActions.GENERATE_TXT_TO_IMG]({ commit }, {nodeId, params}) {
    return new Promise((resolve, reject) => {
      api.content.synthetic
        .txtToImg(nodeId, params)
        .then((response) => {
          const bgActionId = Math.random().toString(36).substr(2, 9)
          const title = "Text to image generation"
          const bgAction = {
            bgActionId,
            title,
            createdAt: new Date(),
            progress: 0,
            status: 'in_progress',
            cancelSource: null,
          }

          commit(`background_actions/${[BackgroundMutations.REGISTER_BACKGROUND_ACTION]}`, bgAction, { root:true })

          const taskOffset = response.data.offset
          const taskTopic = response.data.queue

          poll(taskOffset, taskTopic, bgActionId, commit)
            .finally(() => {
              resolve(response)
            })
        })
        .catch(() => {
          reject()
        })
    })
  },

  [SyntheticActions.GET_3D_LIBRARY_LIST]({}, nodeId) {
    return new Promise((resolve, reject) => {
      api.content.actions.synthetic
        .get3dLibraryList(nodeId)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => reject(error))
      })
  },

  [SyntheticActions.GET_SYNTHETIC_MEDIA_HISTORICAL]({}, {nodeId, options}) {
    return new Promise((resolve, reject) => {
      api.content.synthetic
        .historical(nodeId, options)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => reject(error))
      })
  },

  [SyntheticActions.GENERATE_UPSCALE]({ commit }, {nodeId, upscale}) {
    return new Promise((resolve, reject) => {
      api.content.synthetic
        .upscale(nodeId, upscale)
        .then((response) => {
          const bgActionId = Math.random().toString(36).substr(2, 9)
          const title = "Upscaled image generation"
          const bgAction = {
            bgActionId,
            title,
            createdAt: new Date(),
            progress: 0,
            status: 'in_progress',
            cancelSource: null,
          }

          commit(`background_actions/${[BackgroundMutations.REGISTER_BACKGROUND_ACTION]}`, bgAction, { root:true })

          const taskOffset = response.data.offset
          const taskTopic = response.data.queue

          poll(taskOffset, taskTopic, bgActionId, commit)
            .finally(() => {
              resolve(response)
            })
        })
        .catch((error) => reject(error))
    })
  },
}
