import i18n from '@/i18n.js'

export default {
  fields: [
    {
      name: 'name',
      type: 'text',
      label: 'Name',
      autofocus: true,
      readonly: false,
      rules: [
        v => !!v || i18n.t('form_rules.mandatory'),
      ]
    },
    {
      name: 'active',
      type: 'switch',
      label: 'Active',
      value: true
    }
  ],

  users: [],

  tenants: [],

  groups: [],

  selectedUser: null,

  inactiveFilterValue: false
}
