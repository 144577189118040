<template>
  <svg version="1.1" id="Layer_1" :fill="iconColor" :width="width" :height="height" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
    <path d="M19,3H5C3.89,3,3,3.89,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.89,20.1,3,19,3 M19,5v14H5V5H19z"/>
    <path d="M11.84,10.02l1.79,1.78c0-0.03,0-0.06,0-0.09c0-0.94-0.76-1.69-1.69-1.69C11.9,10.02,11.87,10.02,11.84,10.02 M9.41,10.47
      l0.87,0.87c-0.03,0.12-0.05,0.24-0.05,0.37c0,0.94,0.76,1.69,1.69,1.69c0.12,0,0.25-0.02,0.37-0.05l0.87,0.87
      c-0.38,0.19-0.8,0.3-1.24,0.3c-1.56,0-2.82-1.26-2.82-2.82C9.11,11.27,9.22,10.85,9.41,10.47 M6.29,7.35l1.29,1.29l0.25,0.25
      c-0.93,0.73-1.67,1.69-2.11,2.82c0.98,2.48,3.39,4.23,6.21,4.23c0.87,0,1.71-0.17,2.47-0.47l0.24,0.24l1.65,1.65l0.72-0.72L7,6.63
      M11.93,8.89c1.56,0,2.82,1.26,2.82,2.82c0,0.36-0.07,0.71-0.2,1.03l1.65,1.65c0.85-0.71,1.52-1.63,1.94-2.68
      c-0.98-2.48-3.39-4.23-6.21-4.23c-0.79,0-1.55,0.14-2.26,0.4l1.22,1.21C11.22,8.96,11.57,8.89,11.93,8.89z"/>
  </svg>
</template>

<script>
  export default {
    props: {
      iconName: {
        type: String,
        default: 'checkbox_eye_off'
      },
      iconColor: {
        type: String,
        default: 'currentColor'
      },
      width: {
        type: [Number, String],
        default: 24
      },
      height: {
        type: [Number, String],
        default: 24
      },
    }
  }
</script>