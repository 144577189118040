import AxiosContent from '@/services/api/content/axios_content'

export default {
  getPreview: (nodeId) => {
    return AxiosContent.get(`/rendition/preview/${nodeId}`)
  },

  removeRendition: (nodeId) => {
    return AxiosContent.delete(`/rendition/${nodeId}`)
  }
}
