import AxiosContent from '@/services/api/content/axios_content'

export default {
  getUserNetworkShares: () => {
    return AxiosContent.get('network/share/access')
  },

  configureShareUser: (usersId, data) => {
    return AxiosContent.put(`network/share/user/${usersId}/configure`, data)
  },

  saveNetworkShare: (data) => {
    return AxiosContent.post('network/share', data)
  },

  saveNetworkSharePermission: (networkShareId, params) => {
    return AxiosContent.put(`network/share/${networkShareId}/permission`, params)
  },

  findNetworkDrive: (nodeId) => {
    return AxiosContent.get(`network/share/by/node/${nodeId}`)
  },

  deleteNetworkSharePermission: (networkSharePermissionId) => {
    return AxiosContent.delete(`network/share/permission/${networkSharePermissionId}`)
  },

  deleteNetworkShare: (networkSharePermissionId) => {
    return AxiosContent.delete(`network/share/${networkSharePermissionId}`)
  },
}
