import BackgroundActions from './actions-types'
import BackgroundMutations from './mutations-types'
import axios from "axios";
import api from '@/services/api'

export default {
  [BackgroundActions.CREATE_BACKGROUND_ACTION]({ commit }, {title, icon}) {
    const source = axios.CancelToken.source();

    const bgActionId = Math.random().toString(36).substr(2, 9)

    const bgAction = {
      bgActionId,
      icon,
      title,
      createdAt: new Date(),
      progress: 0,
      status: 'in_progress',
      cancelSource: source,
    }

    commit(BackgroundMutations.REGISTER_BACKGROUND_ACTION, bgAction)
    commit(BackgroundMutations.ADD_NEW_ACTIONS_NUMBER)

    return bgAction
  },

  [BackgroundActions.CREATE_BACKGROUND_ACTION_AND_CHECK_STATUS]({ commit }, bgAction) {
    commit(BackgroundMutations.REGISTER_BACKGROUND_ACTION, bgAction)
    commit(BackgroundMutations.ADD_NEW_ACTIONS_NUMBER)

    const bgActionId = bgAction.bgActionId

    return new Promise((resolve, reject) => {
      const myInterval = setInterval(function() {
        api.content.actions.entry
          .checkPipeline(bgAction.nodeId, bgAction.pipelineId)
          .then((response) => {
            commit(
              `background_actions/${[BackgroundMutations.UPDATE_BACKGROUND_ACTION_STATUS]}`,
              {bgActionId, status: response.data.execution_status},
              { root:true }
            )

            if (response.data.execution_status != 'running') {
              clearInterval(myInterval)

              if (response.data.execution_status == 'done') {
                resolve()
              } else {
                reject()
              }
            }
          })
          .catch(() => {
            commit(
              `background_actions/${[BackgroundMutations.UPDATE_BACKGROUND_ACTION_STATUS]}`,
              {bgActionId, status: 'error'},
              { root:true }
            )

            clearInterval(myInterval);
            reject()
          })
      }, 5000);
    })
  },

  [BackgroundActions.CANCEL_BACKGROUND_ACTION]({ commit }, bgAction) {
    bgAction.cancelSource.cancel()

    commit(BackgroundMutations.REMOVE_BACKGROUND_ITEM, bgAction.bgActionId)
  },

  [BackgroundActions.CLEAR_BACKGROUND_ACTION_LIST]({ commit }) {
    commit(BackgroundMutations.REMOVE_ALL_COMPLETED_BACKGROUND_ACTIONS)
  },

  [BackgroundActions.CLEAR_NEW_ACTIONS_NUMBER]({ commit }) {
    commit(BackgroundMutations.SET_NEW_ACTIONS_NUMBER, 0)
  },
}