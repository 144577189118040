import i18n from '@/i18n.js'

export default {
  fields: [
    {
      name: 'theme_title',
      type: 'text',
      label: 'Title',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      autofocus: true,
      required: true,
      hide: false,
      value: null,
      replicateValToIdx: 1
    },
    {
      name: 'theme_key',
      type: 'slug',
      label: 'Key',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      required: true,
      hide: false,
      replicable: true,
      value: null,
    },
    {
      name: 'tenant_id',
      type: 'select',
      label: 'Tenant',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      itemText: 'title',
      itemValue: 'tenant_id',
      items: [],
      required: true,
      hide: false,
      value: null
    },
    {
      name: 'logo',
      type: 'text',
      label: 'Logo link',
      required: false,
      hide: false,
      value: "",
      saveIn: "theme_config"
    },
    {
      name: 'font',
      type: 'text',
      label: 'Font',
      required: false,
      hide: false,
      value: "",
      saveIn: "theme_config"
    },
    {
      name: 'appBarBgColor',
      type: 'text',
      label: 'App bar: Background color',
      required: false,
      hide: false,
      value: "",
      saveIn: "theme_config"
    },
    {
      name: 'appBarTxtColor',
      type: 'text',
      label: 'App bar: Text color',
      required: false,
      hide: false,
      value: "",
      saveIn: "theme_config"
    },
    {
      name: 'appBackground',
      type: 'text',
      label: 'Background color',
      required: false,
      hide: false,
      value: "",
      saveIn: "theme_config"
    },
    {
      name: 'primary',
      type: 'text',
      label: 'Primary color',
      required: false,
      hide: false,
      value: "",
      saveIn: "theme_config"
    },
    {
      name: 'secondary',
      type: 'text',
      label: 'Second color',
      required: false,
      hide: false,
      value: "",
      saveIn: "theme_config"
    },
    {
      name: 'defaultIconColor',
      type: 'text',
      label: 'Icons color',
      required: false,
      hide: false,
      value: "",
      saveIn: "theme_config"
    },
    {
      name: 'accent',
      type: 'text',
      label: 'Accent color',
      required: false,
      hide: false,
      value: "",
      saveIn: "theme_config"
    },
    {
      name: 'error',
      type: 'text',
      label: 'Error color',
      required: false,
      hide: false,
      value: "",
      saveIn: "theme_config"
    },
    {
      name: 'info',
      type: 'text',
      label: 'Info color',
      required: false,
      hide: false,
      value: "",
      saveIn: "theme_config"
    },
    {
      name: 'success',
      type: 'text',
      label: 'Success color',
      required: false,
      hide: false,
      value: "",
      saveIn: "theme_config"
    },
    {
      name: 'warning',
      type: 'text',
      label: 'Warning color',
      required: false,
      hide: false,
      value: "",
      saveIn: "theme_config"
    },
  ],

  selectedTheme: null,
}
