import api from '@/services/api'
import UnomiConfigActions from './actions-types'
import UnomiConfigMutations from './mutations-types'

export default {

  [UnomiConfigActions.GET_ALL]({ commit }, options = {}) {
    return new Promise((resolve, reject) => {
      api.cdp.unomi_config
        .getAll(options)
        .then((response) => {
          commit(UnomiConfigMutations.SET_UNOMI_CONFIGS, response.data.items)
          resolve(response.data)
        })
        .catch(() => reject())
    })
  },

  [UnomiConfigActions.SAVE_UNOMI_CONFIG]({ state }) {
    return new Promise((resolve, reject) => {
      let data = {}
      
      state.fields.forEach((item) => {
        let fieldName = item.name

        if (fieldName == 'customer_properties') {
          fieldName = 'customer_property_ids'
        }

        data[fieldName] = item.value
      })

      if (state.selectedUnomiConfig) {
        api.cdp.unomi_config
          .updateUnomiConfig(data, state.selectedUnomiConfig.unomi_config_id)
          .then((response) => {
            resolve(response.body)
          })
          .catch(() => reject())
      } else {
        api.cdp.unomi_config
          .createUnomiConfig(data)
          .then((response) => {
            resolve(response.body)
          })
          .catch(() => reject())
      }
    })
  },

  [UnomiConfigActions.DELETE_UNOMI_CONFIG]({ state }) {
    return new Promise((resolve, reject) => {
      api.cdp.unomi_config
        .deleteUnomiConfig(state.selectedUnomiConfig.unomi_config_id)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => reject())
    })
  },
}