<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 24 24">
    <rect class="clas-in-1" x="2.4" y="3" width="19.2" height="18" rx="2.81" ry="2.81" />
    <g>
      <path class="clas-in-2"
        d="M9.39,15.98c0,.08-.03,.12-.12,.12h-1.62c-.08,0-.11-.04-.11-.12V7.51c0-.11,.04-.12,.11-.12h1.62c.08,0,.12,.03,.12,.12V15.98Z" />
      <path class="clas-in-2"
        d="M10.65,12.85c0-1.9,1.42-3.49,3.85-3.49,.11,0,.24,.01,.44,.03v-2.62c0-.07,.04-.09,.09-.09h1.67c.07,0,.08,.03,.08,.08v7.85c0,.31,.01,.7,.05,.97,0,.07-.01,.09-.09,.12-.92,.39-1.79,.53-2.62,.53-1.97,0-3.48-1.12-3.48-3.37Zm4.29-1.89c-.13-.05-.31-.07-.49-.07-1.02,0-1.9,.62-1.9,1.86,0,1.32,.76,1.89,1.77,1.89,.23,0,.44-.01,.62-.08v-3.6Z" />
    </g>
  </svg>
</template>

<style>
  .clas-in-1 {
    fill: #49021f;
  }

  .clas-in-2 {
    fill: #f36;
  }
</style>

<script>
  export default {
    props: {
      iconName: {
        type: String,
        default: 'adobe_indesign'
      },
      width: {
        type: [Number, String],
        default: 24
      },
      height: {
        type: [Number, String],
        default: 24
      },
    }
  }
</script>