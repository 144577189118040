import AxiosCdp from '@/services/api/cdp/axios_cdp'

export default {
  getAll: (params) => {
    return AxiosCdp.get('cdp/property', {params})
  },

  getCustomerPropertiesByTenant: (tenantId, params) => {
    return AxiosCdp.get(`cdp/property/tenant/${tenantId}`, {params})
  },

  createCustomerProperty: (data) => {
    return AxiosCdp.post('cdp/property', data)
  },

  updateCustomerProperty: (data, id) => {
    return AxiosCdp.put(`cdp/property/${id}`, data)
  },
}
