import AxiosContent from '@/services/api/content/axios_content'

export default {
  getAllFileServers: (params) => {
    return AxiosContent.get('fileserver', { params })
  },

  createFileServer: (data) => {
    return AxiosContent.post('fileserver', data)
  },

  updateFileServer: (data, id) => {
    return AxiosContent.put(`fileserver/${id}`, data)
  },
}
