import i18n from '@/i18n.js'

export default {
  fields: [
    {
      name: 'profile_view_title',
      type: 'text',
      label: 'Title',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      autofocus: true,
      required: true,
      hide: false,
      value: null,
      replicateValToIdx: 1
    },
    {
      name: 'profile_view_key',
      type: 'slug',
      label: 'Key',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      required: true,
      hide: false,
      replicable: true,
      value: null,
    },
    {
      name: 'profile_view_description',
      type: 'text',
      label: 'Description',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      required: true,
      hide: false,
      value: null,
    },
    {
      name: 'profile_view_code',
      type: 'textarea',
      label: 'Code',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      required: true,
      hide: false,
      value: null,
    },
    {
      name: 'tenant_id',
      type: 'select',
      label: 'Tenant',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      itemText: 'title',
      itemValue: 'tenant_id',
      items: [],
      required: true,
      hide: false,
      value: null,
      changeEvent: 'tenant-change',
    },
    {
      name: 'customer_properties',
      type: 'select',
      label: 'Customer Properties',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      itemText: 'customer_property_title',
      itemValue: 'customer_property_id',
      items: [],
      required: true,
      hide: false,
      multiple: true,
      value: null,
    },
    {
      name: 'user_groups',
      type: 'select',
      label: 'Groups',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      itemText: 'group_title',
      itemValue: 'user_group_id',
      items: [],
      required: true,
      hide: false,
      multiple: true,
      value: null,
    },
    {
      name: 'active',
      type: 'switch',
      label: 'Active',
      value: true,
      hide: true,
    },
  ],

  exportFields: [
    {
      name: 'profile_view_title',
      type: 'text',
      label: 'Title',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      hide: false,
      disabled: true,
      value: null,
    },
    {
      name: 'profile_view_description',
      type: 'textarea',
      label: 'Description',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      hide: false,
      disabled: true,
      value: null,
    },
    {
      name: 'media',
      type: 'select',
      label: 'Media',
      itemText: 'label',
      itemValue: 'val',
      items: [
        {
          label: 'NONE',
          val: null
        },
        {
          label: 'Google',
          val: 'google'
        },
        {
          label: 'Facebook',
          val: 'facebook'
        },
      ],
      hide: false,
      value: null
    },
  ],

  profileViews: [],

  selectedProfileView: null,

  inactiveFilterValue: false,
}
