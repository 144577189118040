import AxiosCore from '@/services/api/core/axios_core'

export default {
  sendMessage: (data) => {
    return AxiosCore.post(`/notification/send-message`, data)
  },
  sendShareMessage: (data) => {
    return AxiosCore.post(`/notification/send-share-message`, data)
  }
}
