import UserRoles from '@/store/core/user/user-roles'

export default [
  {
    path: '/settings/operation/actionset',
    name: 'Action',
    component: () => import('../views/operation/actionset/Actionset.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false,
      accessRoles: [
        UserRoles.USER_MASTER,
        UserRoles.USER_ADMIN
      ]
    }
  },
  {
    path: '/settings/operation/enabler',
    name: 'Enabler',
    component: () => import('../views/operation/enabler/Enabler.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false,
      accessRoles: [
        UserRoles.USER_MASTER,
        UserRoles.USER_ADMIN
      ]
    }
  },
  {
    path: '/settings/operation/security',
    name: 'SecurityTabs',
    component: () => import('../views/operation/security/SecurityTabs.vue'),
    children: [
      {
        path: 'category',
        name: 'SecurityCategory',
        component: () => import('../views/operation/security_category/SecurityCategory.vue'),
      },
      {
        path: 'group',
        name: 'SecurityGroup',
        component: () => import('../views/operation/security_group/SecurityGroup.vue'),
      },
      {
        path: 'group/:securityGroupId/security_mark',
        name: 'SecurityMark',
        component: () => import('../views/operation/security_mark/SecurityMark.vue'),
      },
    ],
    meta: {
      public: false,
      onlyLoggedOut: false,
      accessRoles: [
        UserRoles.USER_MASTER,
        UserRoles.USER_ADMIN
      ]
    }
  },
  {
    path: '/settings/operation/filter',
    name: 'FilterSettings',
    component: () => import('../views/operation/filter/FilterSettings.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false,
      accessRoles: [
        UserRoles.USER_MASTER,
        UserRoles.USER_ADMIN
      ]
    }
  },
  {
    path: '/settings/operation/filter/edit/:filterId',
    name: 'FilterEdit',
    component: () => import('../views/operation/filter/FilterEdit.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false,
      accessRoles: [
        UserRoles.USER_MASTER,
        UserRoles.USER_ADMIN
      ]
    }
  }
]
