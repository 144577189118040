import api from '@/services/api'
import CustomerPropertyActions from './actions-types'
import CustomerPropertyMutations from './mutations-types'

export default {

  [CustomerPropertyActions.GET_ALL]({ commit }, options = {}) {
    return new Promise((resolve, reject) => {
      api.cdp.customer_property
          .getAll(options)
          .then((response) => {
            commit(CustomerPropertyMutations.SET_CUSTOMER_PROPERTIES, response.data.items)
            resolve(response.data)
          })
          .catch(() => reject())
    })
  },

  [CustomerPropertyActions.GET_CUSTOMER_PROPERTIES_BY_TENANT]({}, {tenantId, options = {}}) {
    return new Promise((resolve, reject) => {
      api.cdp.customer_property
        .getCustomerPropertiesByTenant(tenantId, options)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => reject())
    })
  },

  [CustomerPropertyActions.SAVE_CUSTOMER_PROPERTY]({ state }) {
    return new Promise((resolve, reject) => {
      let data = {}
      
      state.fields.forEach((item) => {
        data[item.name] = item.value
      })

      if (state.selectedCustomerProperty) {
        api.cdp.customer_property
          .updateCustomerProperty(data, state.selectedCustomerProperty.customer_property_id)
          .then((response) => {
            resolve(response.body)
          })
          .catch(() => reject())
      } else {
        api.cdp.customer_property
          .createCustomerProperty(data)
          .then((response) => {
            resolve(response.body)
          })
          .catch(() => reject())
      }
    })
  },
}