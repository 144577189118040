import AxiosContent from '@/services/api/content/axios_content'

export default {
  listSubfolder: (parentId) => {
    return AxiosContent.get(`entry/list/${parentId}`)
  },

  getEntryData: (nodeId) => {
    return AxiosContent.get(`entry/${nodeId}`)
  },

  getParentsFromEntry: (nodeId) => {
    return AxiosContent.get(`/entry/${nodeId}/parent`)
  },

  checkCopyMovePossibility: (data) => {
    return AxiosContent.put(`/entry/copymove/check`, data)
  },
  
  checkEntryNameExists: ({nodeId, data}) => {
    return AxiosContent.post(`/entry/exists/${nodeId}`, data)
  },

  deleteEntry: (nodeId) => {
    return AxiosContent.delete(`/entry/${nodeId}`)
  }
}
