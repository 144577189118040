import SelfServiceTemplateActions from '@/store/content/self_service_template/actions-types'
import api from '@/services/api'

export default {
  [SelfServiceTemplateActions.CREATE_SELF_SERVICE_TEMPLATE]({}, {nodeId, name, file}) {
    return new Promise((resolve, reject) => {
      api.content.self_service_template
        .create(nodeId, name, file)
        .then((response) => {
          resolve(response.data.id)
        })
        .catch((error) => reject(error))
    })
  },

  [SelfServiceTemplateActions.GET_SELF_SERVICE_TEMPLATE]({}, nodeId) {
    return new Promise((resolve, reject) => {
      api.content.self_service_template
        .get(nodeId)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => reject(error))
    })
  },

  [SelfServiceTemplateActions.POST_GENERATE_PREVIEW]({}, {nodeId, data}) {
    return new Promise((resolve, reject) => {
      api.content.self_service_template
        .generatePreview(nodeId, data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => reject(error))
    })
  },

  [SelfServiceTemplateActions.POST_RASTER_TEMPLATES]({}, {nodeId, data}) {
    return new Promise((resolve, reject) => {
      api.content.self_service_template
        .generateRaster(nodeId, data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => reject(error))
    })
  },

  [SelfServiceTemplateActions.GET_HISTORY]({}, nodeId) {
    return new Promise((resolve, reject) => {
      api.content.self_service_template
        .getHistory(nodeId)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => reject(error))
    })
  },

  [SelfServiceTemplateActions.GET_HISTORICAL_DATA]({}, {jstNodeId, generationId}) {
    return new Promise((resolve, reject) => {
      api.content.self_service_template
        .getHistoricalData(jstNodeId, generationId)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => reject(error))
    })
  }
}