import i18n from '@/i18n.js'

export default {
  security_marks: [],

  tableHeaders: [
    {text: 'Key', value: 'security_mark_key'},
    {text: 'Category', value: 'mark_category_id'},
    {text: 'Actions', value: 'actions', sortable: false, width: '180px'}
  ],

  selectedSecurityMark: {},

  selectedParentMark: {},

  fields: [
    {
      name: 'security_mark_key',
      type: 'text',
      label: 'Key',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      autofocus: true,
      required: true,
      hide: false,
      value: null
    },
    {
      name: 'mark_category_id',
      type: 'select',
      label: 'Category',
      items: [],
      itemText: 'mark_category_key',
      itemValue: 'mark_category_id',
      required: true,
      chips: true
    }
  ]
}
