export default {
    GET_USER: 'getUser',
    SAVE_USER: 'saveUser',
    UPDATE_USER: 'updateUser',
    GET_ALL_USERS: 'getAllAdminMasterUsers',
    GET_USER_GROUPS: 'getUserGroups',
    REMOVE_TENANT_ADMIN: 'removeTenantAdmin',
    REMOVE_MASTER_PRIVILEGE: 'removeMasterPrivilege',
    CREATE_USER_BATCH: 'createUserBatch',
    ATTACH_GOOGLE_ACCOUNT: 'attachGoogleAccount'
}