<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 24 24">
    <rect class="clas-ps-1" x="2.4" y="3" width="19.2" height="18" rx="2.81" ry="2.81" />
    <g>
      <path class="clas-ps-2"
        d="M6.43,7.45c0-.08,.01-.11,.08-.11,.54-.01,1.59-.04,2.6-.04,2.68,0,3.61,1.49,3.61,2.94,0,2.15-1.66,3.07-3.71,3.07h-.76v2.62c0,.07-.03,.11-.11,.11h-1.63c-.07,0-.09-.04-.09-.11V7.45Zm1.83,4.22c.23,.01,.37,.01,.74,.01,1.05,0,1.89-.32,1.89-1.41,0-.84-.57-1.37-1.73-1.37-.43,0-.78,.01-.9,.03v2.74Z" />
      <path class="clas-ps-2"
        d="M16.14,10.77c-.62,0-.82,.23-.82,.45s.17,.4,.94,.69c1.61,.58,2.11,1.21,2.11,2.15,0,1.26-.98,2.1-2.74,2.1-.81,0-1.51-.15-1.93-.37-.07-.04-.09-.08-.09-.15v-1.43c0-.08,.05-.12,.11-.08,.61,.39,1.35,.53,1.95,.53s.9-.17,.9-.45c0-.25-.23-.44-1.01-.73-1.5-.56-1.99-1.2-1.99-2.15,0-1.04,.84-2.03,2.59-2.03,.78,0,1.39,.11,1.73,.27,.08,.04,.09,.11,.09,.17v1.33c0,.08-.05,.13-.13,.09-.44-.23-1.12-.39-1.71-.39Z" />
    </g>
  </svg>
</template>

<style>
  .clas-ps-1 {
    fill: #001e36;
  }

  .clas-ps-2 {
    fill: #31a8ff;
  }
</style>

<script>
  export default {
    props: {
      iconName: {
        type: String,
        default: 'adobe_photoshop'
      },
      width: {
        type: [Number, String],
        default: 24
      },
      height: {
        type: [Number, String],
        default: 24
      },
    }
  }
</script>