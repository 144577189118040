import actions from './content/actions'
import file_server from './content/file_server'
import repository from './content/repository'
import entry from './content/entry'
import search from './content/search'
import network_share from './content/network_share'
import rendition from './content/rendition'
import synthetic from './content/synthetic'
import self_service_template from './content/self_service_template'

export default {
  actions,
  file_server,
  repository,
  entry,
  search,
  network_share,
  rendition,
  synthetic,
  self_service_template
}
