import api from '@/services/api'
import UserActions from './actions-types'

export default {
  [UserActions.GET_USER]({}, usersId) {
    return new Promise((resolve, reject) => {
      api.core.user
        .getUser(usersId)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => reject())

      return
    })
  },

  [UserActions.SAVE_USER]({ state }) {
    return new Promise((resolve, reject) => {
      let data = {}

      state.fields.forEach((item) => {
        data[item.name] = item.value
      })

      api.core.user
        .updateUser(data, state.selectedUser.users_id)
        .then((response) => {
          resolve(response.body)
        })
        .catch(() => reject())

      return
    })
  },

  [UserActions.UPDATE_USER]({}, data) {
    return new Promise((resolve, reject) => {
      api.core.user
        .updateUser(data, data.usersId)
        .then((response) => {
          resolve(response.body)
        })
        .catch(() => reject())

      return
    })
  },

  [UserActions.REMOVE_MASTER_PRIVILEGE]({ state }) {
    return new Promise((resolve, reject) => {
      if (! state.selectedUser) {
        reject("No user selected")
      }

      const data = {master: false}

      api.core.user
        .updateUser(data, state.selectedUser.users_id)
        .then((response) => {
          resolve(response.body)
        })
        .catch(() => reject())
    })
  },

  [UserActions.GET_USER_GROUPS]({}, options) {
    return new Promise((resolve, reject) => {
      api.core.user
        .getUserGroups(options)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => reject())
    })
  },

  [UserActions.GET_ALL_USERS]({}, options) {
    return new Promise((resolve, reject) => {
      api.core.user
        .getAllUsers(options)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => reject())
    })
  },

  [UserActions.REMOVE_TENANT_ADMIN]({state}, tenantId) {
    return new Promise((resolve, reject) => {
      api.core.user
        .removeUserTenantAdmin(state.selectedUser.users_id, tenantId)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => reject())
    })
  },

  [UserActions.CREATE_USER_BATCH]({}, params) {
    return new Promise((resolve, reject) => {
      api.core.user
        .createUserBatch(params)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => reject())
    })
  },

  [UserActions.ATTACH_GOOGLE_ACCOUNT]({}, {email, usersId}) {
    return new Promise((resolve, reject) => {
      let params = {external_id: email, users_id: usersId, service: 'google.drive'}

      api.core.user
        .setExternalAccount(params)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => reject())
    })
  },
}