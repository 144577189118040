import api from '@/services/api'
import SecurityMarkActions from './actions-types'

export default {
  [SecurityMarkActions.SAVE_SECURITY_MARK]({ state }, securityGroup) {
    return new Promise((resolve, reject) => {
      let data = {}

      state.fields.forEach((item) => {
        data[item.name] = item.value
      })

      data['security_group_id'] = securityGroup.security_group_id

      if (securityGroup.match_type == 'tree' && state.selectedParentMark) {
        data['mark_parent'] = state.selectedParentMark.id
      }

      if (state.selectedSecurityMark) {
        const secMarkId = state.selectedSecurityMark.security_mark_id ?? state.selectedSecurityMark.id

        api.node.security
          .updateSecurityMark(data, secMarkId)
          .then((response) => {
            resolve(response.body)
          })
          .catch((error) => {
            reject(error.response.data)
          })
      } else {
        api.node.security
          .createSecurityMark(data)
          .then((response) => {
            resolve(response.body)
          })
          .catch((error) => {
            reject(error.response.data)
          })
      }
    })
  },

  [SecurityMarkActions.DELETE_SECURITY_MARK]({ state }) {
    return new Promise((resolve, reject) => {
        api.node.security
          .deleteSecurityMark(state.selectedSecurityMark.security_mark_id ?? state.selectedSecurityMark.id)
          .then((response) => {
            resolve(response.body)
          })
          .catch((error) => {
            reject(error.response.data)
          })
    })
  },
}
