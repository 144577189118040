import ActionsetMutations from './mutations-types'

export default {
  [ActionsetMutations.SET_ACTIONSETS](state, actionsets) {
    state.actionsets = [...actionsets]
  },

  [ActionsetMutations.SET_SELECTED_ACTIONSET](state, actionset) {
    state.selectedActionset = actionset

    state.fields.forEach((field) => {
      field.value = null

      if (actionset) {
        field.value = actionset[field.name]
      }
    })
  },

  [ActionsetMutations.SET_FIELD_TENANTS](state, tenants) {
    state.fields.find(f => f.name == 'tenant_id').items = tenants
  },

  [ActionsetMutations.SET_FIELD_ACTIONS](state, actions) {
    state.fields.find(f => f.name == 'actions_ids').items = actions
  }
}