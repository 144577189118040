export default {
  SET_FIELD_TENANT: 'setFieldTenant',
  SET_FIELD_COMPANY: 'setFieldCompany',
  SET_FIELD_APPLICATIONS: 'setFieldApplications',
  SET_FIELD_APPLICATION_INSTANCES: 'setFieldApplicationInstances',
  SET_SELECTED_GROUP: 'setSelectedGroup',
  SET_GROUPS: 'setGroups',
  SET_SELECTED_TENANT: 'setSelectedTenant',
  SET_LAST_SEARCH_TENANTS: 'setLastSearchTenants',
  SET_LAST_SEARCH_COMPANIES: 'setLastSearchCompanies',
  SET_INACTIVE_FILTER_VAL: 'setInactiveFilterVal',
  SET_THEME_ITEMS: 'setThemeItems'
}