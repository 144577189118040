import api from '@/services/api'
import ClassificationActions from './actions-types'

export default {
  [ClassificationActions.GET_CLASSIFICATION_BY_NODE]({}, nodeId) {
    return new Promise((resolve, reject) => {
      api.node.classification
        .getClassificationByNode(nodeId)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => reject())
    })
  },

  [ClassificationActions.GET_CLASSIFICATION_RECURSIVE_BY_NODE]({}, nodeId) {
    return new Promise((resolve, reject) => {
      api.node.classification
        .getClassificationRecursiveByNode(nodeId)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => reject())
    })
  },

  [ClassificationActions.SIMULATE_MARKS_PERMISSION]({}, params) {
    return new Promise((resolve, reject) => {
      api.node.classification
        .simulateMarksPermission(params)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => reject())
    })
  },

  [ClassificationActions.SAVE_SECURITY_CLASSIFICATION]({}, classification) {
    return new Promise((resolve, reject) => {
      api.node.classification
        .saveSecurityClassification(classification)
        .then(() => {
          resolve()
        })
        .catch(() => reject())
    })
  }
}
