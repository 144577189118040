<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 24 24">
    <rect class="clas-lr-1" x="2.4" y="3" width="19.2" height="18" rx="2.81" ry="2.81" />
    <g>
      <path class="clas-lr-2"
        d="M7.07,15.91c-.11,0-.13-.05-.13-.15V7.35c0-.08,.03-.13,.11-.13h1.65c.07,0,.09,.04,.09,.11v6.92h3.8c.08,0,.11,.05,.09,.12l-.24,1.45c-.01,.09-.07,.11-.13,.11H7.07Z" />
      <path class="clas-lr-2"
        d="M15.04,9.31c.09,0,.13,.04,.16,.12,.07,.17,.13,.45,.15,.74,.44-.52,1.17-1,2.11-1,.08,0,.11,.03,.11,.11v1.63c0,.07-.03,.09-.12,.09-.96-.04-1.73,.25-2.01,.57v4.22c-.01,.08-.04,.11-.13,.11h-1.59c-.11,0-.13-.04-.13-.12v-4.58c0-.57-.01-1.34-.07-1.81,0-.07,.01-.09,.08-.09h1.45Z" />
    </g>
  </svg>
</template>

<style>
  .clas-lr-1 {
    fill: #001e36;
  }

  .clas-lr-2 {
    fill: #31a8ff;
  }
</style>

<script>
  export default {
    props: {
      iconName: {
        type: String,
        default: 'adobe_lightroom'
      },
      width: {
        type: [Number, String],
        default: 24
      },
      height: {
        type: [Number, String],
        default: 24
      },
    }
  }
</script>