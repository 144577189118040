import AxiosCore from '@/services/api/core/axios_core'

export default {
  createCompany: (data) => {
    return AxiosCore.post('company', data)
  },

  updateCompany: (data, id) => {
    return AxiosCore.put('company/' + id, data)
  },

  getAllCompanies: (params) => {
    return AxiosCore.get('company', { params })
  },

  deleteCompany: (id) => {
    return AxiosCore.delete('company/' + id)
  },

  getCompaniesByTenant(tenantId) {
    return AxiosCore.get('company/tenant/' + tenantId)
  },

  getCompaniesByUserGroup(userGroupId, params) {
    return AxiosCore.get(`company/usergroup/${userGroupId}`, { params })
  },

  addCompanyUserGroupRelationshipByKey(userGroupId, params) {
    return AxiosCore.put(`company/usergroup/${userGroupId}`, params)
  },

  deleteCompanyUserGroupRelationship(companyId, userGroupId) {
    return AxiosCore.delete(`company/${companyId}/usergroup/${userGroupId}`)
  }
}
