import api from '@/services/api'
import EntryActions from './actions-types'
import EntryMutations from './mutations-types'

export default {
  [EntryActions.LIST_SUBFOLDER]({ commit }, {parentId, updateList = true}) {
    if (updateList) {
      commit(EntryMutations.SET_ENTRIES, {})
    }

    return new Promise((resolve, reject) => {
      api.content.entry
        .listSubfolder(parentId)
        .then((response) => {
          if (updateList) {
            commit(EntryMutations.SET_ENTRIES, response.data)
          }

          resolve(response.data)
        })
        .catch((error) => reject(error))
    })
  },

  [EntryActions.GET_ENTRY_DATA]({}, nodeId) {
    return new Promise((resolve, reject) => {
      api.content.entry
        .getEntryData(nodeId)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => reject(error))
    })
  },

  [EntryActions.SEARCH_BY_TERM]({ commit }, params) {
    return new Promise((resolve, reject) => {
      let [searchParams, showBreadcrumb] = params;
      api.content.search
        .searchByTerm(searchParams)
        .then((response) => {
          const list = showBreadcrumb ? {entries: response.data} : {entries: response.data, breadcrumb: []}
          commit(EntryMutations.SET_ENTRIES, list)
          resolve(list)
        })
        .catch((error) => reject(error))
    })
  },

  [EntryActions.GET_ENTRY_PARENTS_IDS]({}, nodeId) {
    return new Promise((resolve, reject) => {
      api.content.entry
        .getParentsFromEntry(nodeId)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => reject(error))
    })
  },

  [EntryActions.CHECK_COPY_MOVE_POSSIBILITY]({}, params) {
    return new Promise((resolve, reject) => {
      api.content.entry
        .checkCopyMovePossibility(params)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error))
    })
  },

  [EntryActions.CHECK_ENTRY_NAME_EXISTS]({}, params) {
    return new Promise((resolve, reject) => {
      api.content.entry
        .checkEntryNameExists(params)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error))
    })
  },

  [EntryActions.DELETE_ENTRY]({}, nodeId) {
    return new Promise((resolve, reject) => {
      api.content.entry
        .deleteEntry(nodeId)
        .then(() => resolve())
        .catch((error) => reject(error))
    })
  }
}
