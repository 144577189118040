import i18n from '@/i18n.js'

export default {
  filters: [],

  tableHeaders: [
    {text: 'Key', value: 'filter_key'},
    {text: 'Tenant', value: 'tenant_id'},
    {text: 'Actions', value: 'actions', sortable: false}
  ],

  selectedFilter: {},

  fields: [
    {
      name: 'filter_key',
      type: 'text',
      label: 'Key',
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      autofocus: true,
      required: true,
      hide: false,
      value: null
    },
    {
      name: 'tenant_id',
      type: 'select',
      label: 'Tenant',
      items: [],
      itemText: 'title',
      itemValue: 'tenant_id',
      required: true,
      hide: false,
      chips: true,
      multiple: false
    },
    {
      name: 'active',
      type: 'switch',
      label: 'Active',
      value: true,
      hide: true,
    }
  ],

  fieldsList: [],

  groupsList: [],
}
