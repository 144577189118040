import AxiosNode from '@/services/api/node/axios_node'

export default {
  getGrantsByNode: (nodeId) => {
    return AxiosNode.get(`/grant/node/${nodeId}`)
  },
  getGrantsByNodeWithParents: (nodeId) => {
    return AxiosNode.get(`/grant/${nodeId}/parents`, {params: {only_recursive:1}})
  },
  addGrant: (data) => {
    return AxiosNode.post(`/grant`, data)
  },

  deleteGrant: (nodeId) => {
    return AxiosNode.delete(`/grant/${nodeId}`)
  },
}