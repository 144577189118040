import api from '@/services/api'
import RepositoryActions from './actions-types'
import RepositoryMutations from './mutations-types'

export default {
  [RepositoryActions.GET_ADMIN_REPOSITORIES]({ commit }, options = {}) {
    commit(RepositoryMutations.SET_REPOSITORIES, [])

    return new Promise((resolve, reject) => {
      api.content.repository
        .getAdminRepositories(options)
        .then((response) => {
          commit(RepositoryMutations.SET_REPOSITORIES, response.data.items)

          resolve(response.data)
        })
        .catch(() => reject())
    })
  },

  [RepositoryActions.GET_ALL_REPOSITORIES]({ commit }, options = {}) {
    commit(RepositoryMutations.SET_REPOSITORIES, [])

    return new Promise((resolve, reject) => {
      api.content.repository
        .getAllRepositories(options)
        .then((response) => {
          commit(RepositoryMutations.SET_REPOSITORIES, response.data.items)

          resolve(response.data)
        })
        .catch(() => reject())
    })
  },

  [RepositoryActions.SAVE_REPOSITORY]({ state }) {
    return new Promise((resolve, reject) => {
      let data = {}
      
      state.fields.forEach((item) => {
        let fieldName = item.name

        data[fieldName] = item.value
      })

      if (state.selectedRepository) {
        api.content.repository
          .updateRepository(data, state.selectedRepository.repository_id)
          .then((response) => {
            resolve(response.data)
          })
          .catch(() => reject())
      } else {
        api.content.repository
          .createRepository(data)
          .then((response) => {
            resolve(response.data)
          })
          .catch(() => reject())
      }
    })
  },

  [RepositoryActions.SAVE_GDRIVE_CONFIG]({}, {repositoryId, documentId, ownerEmail}) {
    return new Promise((resolve, reject) => {
      api.content.repository
        .configureGdrive(repositoryId, {document_id: documentId, owner_email: ownerEmail})
        .then((response) => resolve(response.data))
        .catch(() => reject())
    })
  },

  [RepositoryActions.GET_GDRIVE_CONFIG]({}, repositoryId) {
    return new Promise((resolve, reject) => {
      api.content.repository
        .getGdriveConfig(repositoryId)
        .then((response) => resolve(response.data))
        .catch(() => reject())
    })
  }
}
