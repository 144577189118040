import CustomerPropertyMutations from './mutations-types'

export default {

  [CustomerPropertyMutations.SET_CUSTOMER_PROPERTIES](state, customerProperties) {
    state.customerProperties = [...customerProperties]
  },
  
  [CustomerPropertyMutations.SET_SELECTED_CUSTOMER_PROPERTY](state, customerProperty) {
    state.selectedCustomerProperty = customerProperty

    state.fields.forEach((field) => {
      field.value = null

      if (customerProperty) {
        field.value = customerProperty[field.name]
      }

      if (field.name == 'customer_property_title') {
        field.autofocus = true

        if (customerProperty) {
          field.autofocus = false
        }
      }

      if (field.name == 'customer_property_key') {
        field.disabled = false
        field.replicable = true
        
        if (customerProperty) {
          field.disabled = true
          field.replicable = false
        }
      }

      if (field.name == 'active') {
        if (customerProperty) {
          field.hide  = false

          return
        }
        
        field.value = true
        field.hide = true
      }
    })
  },

  [CustomerPropertyMutations.SET_FIELD_TENANTS](state, tenants) {
    state.fields.find(f => f.name == 'tenant_id').items = tenants
  },

  [CustomerPropertyMutations.SET_INACTIVE_FILTER_VAL](state, active) {
    state.inactiveFilterValue = active
  }
}