import api from '@/services/api'
import FileServerActions from './actions-types'
import FileServerMutations from './mutations-types'

export default {
  [FileServerActions.GET_ALL_FILE_SERVERS]({ commit }, options = {}) {
    return new Promise((resolve, reject) => {
      api.content.file_server
        .getAllFileServers(options)
        .then((response) => {
          commit(FileServerMutations.SET_FILE_SERVERS, response.data.items)

          resolve(response.data)
        })
        .catch(() => reject())
    })
  },

  [FileServerActions.SAVE_FILE_SERVER]({ state }) {
    return new Promise((resolve, reject) => {
      let data = {}
      
      state.fields.forEach((item) => {
        let fieldName = item.name

        data[fieldName] = item.value
      })

      if (state.selectedFileServer) {
        api.content.file_server
          .updateFileServer(data, state.selectedFileServer.file_server_id)
          .then((response) => {
            resolve(response.body)
          })
          .catch(() => reject())
      } else {
        api.content.file_server
          .createFileServer(data)
          .then((response) => {
            resolve(response.body)
          })
          .catch(() => reject())
      }
    })
  },
}
