import AxiosNode from '@/services/api/node/axios_node'

export default {
  getClearanceByGroup: (groupId) => {
    return AxiosNode.get(`/clearance/group/${groupId}`)
  },

  getUserGroupsClearances: () => {
    return AxiosNode.get(`/clearances`)
  },

  saveSecurityClearance: (clearance) => {
    return AxiosNode.put('/clearance/collection', clearance)
  }
}