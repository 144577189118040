import AxiosCore from '@/services/api/core/axios_core'

export default {
  getAll: (params) => {
    return AxiosCore.get('application_instance', {params})
  },

  createApplicationInstance: (data) => {
    return AxiosCore.post('application_instance', data)
  },

  updateApplicationInstance: (data, id) => {
    return AxiosCore.put(`application_instance/${id}`, data)
  },

  getUserAppInstances: () => {
    return AxiosCore.get('usergroup/application')
  },
}
