<template>
  <v-menu
    offset-y
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge 
        :content="newActionsNumber" 
        :value="newActionsNumber"
        color="primary"
        overlap
        bordered
      >
        <v-progress-circular
          @click="userHasViewedMenu"
          v-bind="attrs"
          v-on="on"
          :value="totalProgress"
          :rotate="-90"
          color="primary"
        ></v-progress-circular>
      </v-badge>
    </template>

    <v-card v-if="actionList.length > 0">

      <v-list
        class="list overflow-y-auto"
        dense
      >
        <template v-for="(item, index) in actionList.slice().reverse()">

          <BackgroundActionItem
            :key="item.id"
            :data="item"
          />

          <v-divider
            v-if="index < actionList.length - 1"
            :key="index"
          ></v-divider>

        </template>
      </v-list>

      <v-divider />

      <v-col class="bottom-container text-right">
        <v-btn
          text
          small
          color="primary"
          class="text-right"
          @click.stop="clearActionList"
        >
          {{ $t('mark_read') }}
        </v-btn>
      </v-col>

    </v-card>

    <v-card 
      v-else
      width="300"
      height="150"
    >
      <v-card-text align="center">
        <v-icon
          color="defaultIconColor"
          class="mt-4"
          large
        >mdi-check-decagram</v-icon>
      </v-card-text>

      <v-card-subtitle
        align="center"
      >{{ $t('no_background_actions') }}</v-card-subtitle>
    </v-card>

  </v-menu>
</template>

<style scoped>
  .list {
    max-height: 300px;
  }

  .bottom-container {
    padding: 2px;
  }
</style>

<script>

import { mapActions, mapState } from 'vuex'

import BackgroundActionItem from '@/components/app/BackgroundActionItem.vue'

import BackgroundActions from '@/store/background_actions/actions-types.js'

export default {
  name: 'BackgroundActionList',

  components: {
    BackgroundActionItem,
  },

  created() {
    window.addEventListener('beforeunload', this.handler)
  },

  computed: {
    ...mapState({
      actionList: state => state.background_actions.list,
      newActionsNumber: state => state.background_actions.newActionsNumber,
    }),

    totalProgress() {
      const inProgress = this.actionList.filter(i => i.status == 'in_progress')

      const totalItems = inProgress.length

      if (totalItems <= 0) {
        return 0
      }

      let totalProgress = inProgress.reduce((sum, { progress }) => {
        return sum + progress;
      }, 0)

      return totalProgress / totalItems
    },
  },

  methods: {
    ...mapActions('background_actions', [
      BackgroundActions.CLEAR_BACKGROUND_ACTION_LIST,
      BackgroundActions.CLEAR_NEW_ACTIONS_NUMBER,
    ]),

    handler() {
      if (this.actionList.some(i => i.status == 'in_progress')) {
        alert(this.$t('alert_background_actions_in_progress'))
      }
    },

    clearActionList() {
      this[BackgroundActions.CLEAR_BACKGROUND_ACTION_LIST]()
    },

    userHasViewedMenu() {
      this[BackgroundActions.CLEAR_NEW_ACTIONS_NUMBER]()
    },
  },
}

</script>