import ThemeMutations from './mutations-types'

export default {
  [ThemeMutations.SET_SELECTED_THEME](state, {theme, tenants, isMaster = false}) {
    state.selectedTheme = theme

    const spreadedConfigTheme = {...theme, ...JSON.parse(theme?.theme_config || "{}")}

    state.fields.forEach((field) => {
      if (field.name in spreadedConfigTheme) {
        field.value = spreadedConfigTheme[field.name]
      }

      if (field.name == 'tenant_id') {
        if (! theme && isMaster) {
          field.items = [...tenants]
          field.readonly = false
        } else {
          field.readonly = true
        }

        return
      }

      field.disabled = ! isMaster
      field.readonly = ! isMaster
    })
  },

  [ThemeMutations.SET_FIELD_TENANTS](state, payload) {
    state.fields.find(f => f.name == 'tenant_id').items = [...payload]
  },
}
