import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import i18n from './i18n'
import VueMask from 'v-mask'
import GlobalProperties from './globalProperties.js'
import Google from './plugins/google-apis'

Vue.config.productionTip = false

Vue.use(VueMask)

Vue.use(GlobalProperties)

Vue.use(Google, {
  apiKey: process.env.VUE_APP_GDOCS_API_KEY,
  clientId: process.env.VUE_APP_GDOCS_CLIENT_ID,
  discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'],
  scope: 'https://www.googleapis.com/auth/drive'
})

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
